import { noop } from 'lodash';
import React from 'react';
import { BsX } from 'react-icons/bs';
import styled from 'styled-components';

export default ({
  className,
  children,
  show = true,
  showClose = true,
  onClose = noop,
}) => (
  <Container className={show ? 'show' : ''}>
    <Content className={className}>{children}</Content>
    {showClose && (
      <CloseContainer onClick={() => onClose()}>
        <BsX />
      </CloseContainer>
    )}
  </Container>
);

//
// Styled Components
//

const Container = styled.div`
  position: absolute;
  top: 50px;
  bottom: 50px;
  left: 50%;
  border-radius: 20px;
  background-color: white;
  padding: 40px 0;
  min-width: ${() => `min(${window.innerWidth * 0.9}px, 600px)`};
  max-width: 600px;
  transition: transform 350ms;
  transform: translate(-50%, 100%);

  @media (max-width: 450px) {
    top: 30px;
    bottom: 30px;
  }

  &.show {
    transform: translate(-50%, 0);
  }
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 24px;
  box-sizing: border-box;
  overflow: auto;
`;

const CloseContainer = styled.div`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
  font-size: 32px;

  @media (max-width: 450px) {
    top: 16px;
    right: 14px;
    font-size: 24px;
  }
`;
