import styled from 'styled-components';
import AsteriskIcon from '../../../assets/images/red_asterisk.svg';

export default styled.img.attrs(() => ({
  src: AsteriskIcon,
  alt: 'alert',
}))`
  width: 30px;
  height: 30px;
`;
