import moment from 'moment';
import { AgePicker } from '../../components/forms/selectors/AgePicker';
import withHeaderBox from '../../components/hoc/withHeaderBox';
import withInputContainer from '../../components/hoc/withInputContainer';

const MAX_DATE = moment().toDate();
const MIN_DATE = moment('2021-01-01').toDate();

class SymptomDate extends AgePicker {
  name = 'symptomsDate';

  id = 'symptom-picker';

  inputBoxName = 'symptomsDatePicker';

  maxDate() {
    return MAX_DATE;
  }

  minDate() {
    return MIN_DATE;
  }

  defaultDate() {
    return '2021-01-01';
  }
}

export default withHeaderBox(
  withInputContainer(SymptomDate, {
    label: 'Date of Onset Symptoms',
  }),
);
