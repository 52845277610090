import moment from 'moment';
import { AgePicker } from '../../components/forms/selectors/AgePicker';
import withHeaderBox from '../../components/hoc/withHeaderBox';
import withInputContainer from '../../components/hoc/withInputContainer';

const MAX_DATE = moment().toDate();
const MIN_DATE = moment('2021-01-01').toDate();

class SampleDatePicker extends AgePicker {
  name = 'date';

  id = 'sampleDate-picker';

  inputBoxName = 'sampleDate';

  maxDate() {
    return MAX_DATE;
  }

  minDate() {
    return MIN_DATE;
  }

  defaultDate() {
    return moment();
  }
}

export default withHeaderBox(
  withInputContainer(SampleDatePicker, {
    label: 'Sample Date',
  }),
);
