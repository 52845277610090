import React, { PureComponent } from 'react';
import { HeaderBox } from '../layout';
import { Description, Title } from '../text';
import Option from './Option';

export default class MultiSelection extends PureComponent {
  onChange = (event) => {
    const { name } = this.props;
    const { value } = event.target;
    this.props.onChange({ name, value });
  };

  renderOptions() {
    const { data, name, selectedValues, onChange, onEnter, ...restProps } =
      this.props;
    return data.options.map((item, index) => {
      const key = `opt-${index}`;
      const { label, value } = item;
      const checked = selectedValues.indexOf(value) !== -1;
      return (
        <Option
          key={key}
          name={name}
          box={false}
          value={value}
          className="group-option"
          type="checkbox"
          checked={checked}
          onChange={this.onChange}
          {...restProps}
        >
          <Title>{label}</Title>
        </Option>
      );
    });
  }

  render() {
    const { title } = this.props.data;
    return (
      <HeaderBox className="group-selection" theme="white">
        <Title className="group-title">{title}</Title>
        <Description className="group-desc">
          Select all that applies
        </Description>
        {this.renderOptions()}
      </HeaderBox>
    );
  }
}
