import { createSlice } from '@reduxjs/toolkit';

export const userExtraInfoSlice = createSlice({
  name: 'userExtraInfo',
  initialState: {
    patientCategory: '',
    occupation: '',
    aarogyaSetuAppDownloaded: '',
    contactWithLabConfirmedPatient: '',
  },
  reducers: {
    updateUserExtraInfo: (state, { payload }) => {
      const { name: field, value } = payload;
      state[field] = value;
    },
    setUserExtraInfo: (state, { payload }) => ({ ...state, ...payload }),
  },
});

export const { updateUserExtraInfo, setUserExtraInfo } =
  userExtraInfoSlice.actions;

export default userExtraInfoSlice.reducer;
