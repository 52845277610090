import React from 'react';
import InputBox from '../InputBox';

export default function MobileInput(props) {
  const {
    label = 'Contact number',
    placeholder = '+91 Contact Number',
    ...restProps
  } = props;
  return (
    <InputBox
      name="mobile"
      label={label}
      type="tel"
      placeholder={placeholder}
      maxLength="16"
      {...restProps}
    />
  );
}
