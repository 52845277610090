import React from 'react';
import styled from 'styled-components';
import { withStepContainer } from '../../components/hoc/withLabelBox';
import FadeInView from '../../components/layout/FadeInView';
import { Text } from '../../components/text';

const StepContent = ({ data, image }) => {
  const { description } = data;
  return (
    <>
      {description.map((desc) => (
        <Text key={desc}>{desc}</Text>
      ))}
      {image && <Image src={image.src} alt={image.title} />}
    </>
  );
};

const StepItem = withStepContainer(StepContent);

export default class Step extends FadeInView {
  renderComponent({ renderItem, renderFooter, step, ...props }) {
    const components = [
      <StepItem {...props} step={step} key={`step-${step}`} />,
    ];
    if (renderFooter) {
      components.push(renderFooter());
    }
    return components;
  }
}

//
// Styled Components
//

const Image = styled.img`
  width: 96%;
  max-width: 450px;
  margin: 30px auto 20px;
  display: block;
`;
