import React from 'react';
import MultiSelection from '../../components/forms/MultiSelection';

const data = {
  title: 'Symptoms',
  options: [
    { label: 'Fever', value: 'fever' },
    { label: 'Cough', value: 'cough' },
    { label: 'Diarrhoea', value: 'diarrhoea' },
    { label: 'Breathlessness', value: 'breathlessness' },
    { label: 'Sore Throat', value: 'sore_throat' },
    { label: 'Loss of Taste', value: 'loss_of_taste' },
    { label: 'Loss of Smell', value: 'loss_of_smell' },
    { label: 'None of the above', value: 'none' },
  ],
};

export default function Symptoms({ value = [], ...restProps }) {
  return (
    <MultiSelection
      name="symptoms"
      data={data}
      selectedValues={value}
      {...restProps}
    />
  );
}
