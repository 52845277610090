import styled, { css } from 'styled-components';

export const absoluteFill = css`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

export const boxCss = css`
  position: relative;
  background-color: #fff;
  border-radius: 20px;
  box-sizing: border-box;
  box-shadow: 0 0 38px 0 rgba(0, 0, 0, 0.15);
  padding: 26px;
  margin: 24px;
  display: block;
`;

export const Box = styled.div`
  ${boxCss};
`;

export const HeaderBox = styled(Box)`
  margin-top: 10px;
  background-color: ${(props) =>
    props.theme === 'white' ? 'white' : '#970e53'};
  p,
  li {
    color: ${(props) => (props.theme === 'white' ? '#664849' : 'white')};
  }
  p:nth-child(2) {
    margin-top: ${(props) => (props.theme === 'white' ? 0 : '1.4rem')};
  }
`;

export const inputCss = css`
  border: 0;
  height: 47px;
  line-height: 47px;
  text-align: right;
  box-sizing: border-box;
  width: 100%;
  padding-right: 10px;
  padding-left: 70px;
  outline: none;
`;

export const fixedDiv = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 888;
`;

export const fade = css`
  transition: ${(props) => `opacity ${props.duration}s linear`};
  opacity: 1;
  display: none;

  &.active {
    opacity: 1;
    display: block;
  }

  &.fadeIn,
  &.fadeOut {
    opacity: 0;
    display: block;
  }
`;
