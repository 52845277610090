import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    name: '',
    gender: '',
    birthDate: '',
    mobile: '',
    mobileOwner: 'patient',
    email: '',
    nationality: 'IN',
    passportNumber: '',
    aadharNumber: '',
    address: '',
    state: '-1',
    district: '-1',
    pincode: '', // zipcode
  },
  reducers: {
    updateUserProfile: (state, { payload }) => {
      const { name: field, value } = payload;
      state[field] = value;
      if (field === 'state') {
        state.district = '-1';
      }
    },
    setUserInfo: (state, { payload }) => ({ ...state, ...payload }),
  },
});

export const { updateUserProfile, setUserInfo } = userSlice.actions;

export default userSlice.reducer;
