import React from 'react';
import SingleSelection from '../../components/forms/SingleSelection';

const data = {
  title: 'Vaccination Type',
  options: [
    { label: 'Covishield', value: 'Covishield' },
    { label: 'Covaxin', value: 'Covaxin' },
  ],
};

export default function VaccineType({ value, ...restProps }) {
  return (
    <SingleSelection
      name="type"
      data={data}
      selectedValue={value}
      {...restProps}
    />
  );
}
