import React from 'react';
import styled from 'styled-components';
import SplashImage from '../../../assets/images/splash.png';
import Logo from '../../../assets/images/logo_pink.png';
import withFadeOverlay from '../hoc/withFadeOverlay';

function Splash() {
  return (
    <Container>
      <Image src={SplashImage} />
      <LogoImage src={Logo} />
    </Container>
  );
}

const Container = styled.div`
  background-color: #cf7b99;
  width: 100%;
  height: 100%;
  text-align: center;
`;

const Image = styled.img`
  width: 40vw;
  min-width: 350px;
  max-height: ${window.innerHeight * 0.55}px;
  margin-top: 100px;
  object-fit: contain;
  vertical-align: middle;
`;

const LogoImage = styled.img`
  width: 150px;
  position: absolute;
  left: ${(window.innerWidth - 150) / 2}px;
  bottom: 60px;
`;

export default withFadeOverlay(Splash);
