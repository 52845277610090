export function getGenderText(value) {
  switch (value) {
    case 'male':
      return 'Male';
    case 'female':
      return 'Female';
    case 'trans':
      return 'Trans';
    default:
      return '';
  }
}

export function getMobileOwnerText(value) {
  return value === 'patient' ? 'Patient' : 'Relative';
}

export function getResultText(value) {
  if (value === '+') {
    return 'POSITIVE';
  }
  if (value === '-') {
    return 'NEGATIVE';
  }
  return 'INVALID';
}

export function getTestingType(type) {
  if (type === 's2covid') {
    return 'S2 - Antigen Nasal Swab';
  }
  return 'Unknown';
}

export function getSampleType(type) {
  if (type === 'Nasal swab') {
    return 'Antigen Nasal Swab';
  }
  return 'Sputum';
}
