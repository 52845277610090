import React from 'react';
import styled from 'styled-components';
import { boxCss } from '../layout';

import { Description, fonts, textStyles, Title } from '../text';

export default function Option(props) {
  const { children, checked, box = true, className, ...rest } = props;
  return (
    <Label className={className} box={box}>
      <TextContainer>{children}</TextContainer>
      <InputContainer>
        <Input {...rest} checked={checked} aria-checked={checked} />
        <Fill />
      </InputContainer>
    </Label>
  );
}

//
// Styled Components
//

const Label = styled.label`
  ${(props) => (props.box ? boxCss : '')}
  cursor: pointer;
`;

const TextContainer = styled.div`
  margin-right: 38px;

  ${Title} {
    ${textStyles.text};
    font-weight: bold;
  }

  ${Description} {
    ${textStyles.text};
    ${fonts.primary};
    margin-top: 10px;
  }
`;

const InputContainer = styled.span`
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  right: 26px;
  top: 0px;
`;

const Fill = styled.div`
  position: relative;
  border: 1px solid #cf7b99;
  background: #fff;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  transition: background 0.3s ease-in;
  pointer-events: none;
  z-index: 1;

  &::before {
    content: '';
    border: 1px solid #cf7b99;
    background: #fff;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    transition: background 0.3s ease-in;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const Input = styled.input.attrs(({ type }) => ({
  type: type || 'radio',
}))`
  cursor: pointer;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  opacity: 0;
  z-index: 2;

  &:focus {
    outline: none;
  }

  &:checked {
    & ~ ${Fill}::before {
      background: #cf7b99;
    }
  }
`;
