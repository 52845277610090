const callbacks = [];

export const registerLocationCallback = (callback) => {
  if (typeof callback === 'function') {
    callbacks.push(callback);
  }
};

export const registerOnChangeLocationListener = () => {
  window.addEventListener('popstate', () => {
    while (callbacks.length > 0) {
      const callback = callbacks.pop();
      if (typeof callback === 'function') {
        callback();
      }
    }
  });
};
