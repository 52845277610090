import React, { PureComponent } from 'react';
import InputBox from '../../../../components/forms/InputBox';
import { HeaderBox } from '../../../../components/layout';
import LGDirectory from './LGDirectory';

export default class AddressInput extends PureComponent {
  render() {
    const { value: user, onChange } = this.props;
    const showPincode = `${user.state}` !== '-1' && `${user.district}` !== '-1';

    return (
      <HeaderBox theme="white">
        <InputBox
          name="address"
          label="Address"
          value={user.address}
          onChange={onChange}
        />
        <LGDirectory value={user} onChange={onChange} />
        {showPincode && (
          <InputBox
            name="pincode"
            label="Pincode"
            placeholder="123456"
            maxLength="6"
            value={user.pincode}
            onChange={onChange}
          />
        )}
      </HeaderBox>
    );
  }
}
