import React from 'react';
import InputBox from '../../../components/forms/InputBox';
import { HeaderBox } from '../../../components/layout';

export default function AadharInput(props) {
  return (
    <HeaderBox theme="white">
      <InputBox
        name="aadharNumber"
        label="Aadhar number"
        maxLength="12"
        type="number"
        {...props}
      />
    </HeaderBox>
  );
}
