import React, { PureComponent } from 'react';
import styled from 'styled-components';
import withFadeInView from '../../components/hoc/withFadeInView';
import { withLabelBox } from '../../components/hoc/withLabelBox';

class ResultImage extends PureComponent {
  renderImage() {
    if (!this.props.resultImage) {
      return null;
    }
    return <img src={this.props.resultImage} alt="result" />;
  }

  render() {
    return (
      <Container>
        <InnerContainer>{this.renderImage()}</InnerContainer>
      </Container>
    );
  }
}

export default withFadeInView(withLabelBox(ResultImage));

//
// Styled Components
//

const Container = styled.div`
  position: relative;
  padding: 20px 10px 12px 0;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 28px 12px;
  background: #d1d1d1;
  border-radius: 20px;
  box-sizing: border-box;
  width: 150px;
  height: 350px;
  margin: 0 auto;
`;
