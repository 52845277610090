import React from 'react';
import Webcam from 'react-webcam';
import styled from 'styled-components';
import BackIcon from '../../../assets/images/back_button.svg';
import DownArrowIcon from '../../../assets/images/down_arrow.svg';
import Shutter from '../../../assets/images/shutter_button.svg';
import { PageContainer } from '../../components/layout/PageContainer';
import * as text from '../../components/text';
import OverlayGuidance from './OverlayGuidance';

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: { exact: 'environment' },
};

export default React.forwardRef((props, ref) => {
  const renderHeader = () => (
    <Header>
      <Title>Take Photo Of Your Test</Title>
      <Text>
        Align the test strip to the rectangle guide line and take a picture.
      </Text>
      <DownArrow />
    </Header>
  );

  const renderFooter = () => <ShutterButton onClick={props.onTakePhoto} />;

  return (
    <>
      <Webcam
        audio={false}
        ref={ref}
        height="100%"
        width="100%"
        screenshotFormat="image/jpeg"
        videoConstraints={videoConstraints}
      />
      <OverlayGuidance
        renderHeader={renderHeader}
        renderFooter={renderFooter}
      />
      <TopContainer>
        <BackButton onClick={props.onBack} />
      </TopContainer>
    </>
  );
});

const TopContainer = ({ children }) => (
  <HeaderContainer>
    <PageContainer>{children}</PageContainer>
  </HeaderContainer>
);

//
// Styled Components
//

const Header = styled.div`
  padding-top: 32px;
  text-align: center;
`;

const Title = styled(text.Title)`
  color: white;
  font-size: 2.2rem;
  margin-bottom: 10px;
  margin-top: 0;

  @media (max-width: 360px) {
    font-size: 1.65rem;
    line-height: 34px;
  }
`;

const Text = styled(text.Text)`
  color: white;
  font-size: 1.1rem;
  width: 260px;
  margin: 0 auto;
`;

const ShutterButton = styled.button.attrs(() => ({
  type: 'button',
  alt: 'Take Photo',
}))`
  background-color: transparent;
  background-image: url(${Shutter});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 56px;
  height: 56px;
  border: 0;
  display: block;
  margin: 10px auto !important;

  &:active,
  &:hover {
    background-color: transparent;
  }

  &:not(:active) {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(16deg)
      brightness(107%) contrast(102%);
  }
`;

const DownArrow = styled.img.attrs(() => ({
  src: DownArrowIcon,
  alt: '',
}))`
  position: absolute;
  left: 50%;
  top: 126px;
  width: 16px;
  margin-left: -8px;
  z-index: 1;
`;

const HeaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

const BackButton = styled.button.attrs(() => ({
  type: 'button',
  alt: 'Back',
}))`
  background-color: transparent;
  background: url(${BackIcon}) center center no-repeat;
  background-size: 20px;
  width: 50px;
  height: 50px;
  border: 0;
  display: block;
  margin: 24px 0 0 10px;

  &:active,
  &:hover {
    background-color: transparent;
    background-size: 30px;
  }
`;
