import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { scrollToTop } from '../../../helpers';
import { goToUserProfilePage } from '../../../helpers/router';
import { submitUserExtraInfo } from '../../../redux/actions/index';
import { getUserExtraInfo } from '../../../redux/selectors';
import { updateUserExtraInfo } from '../../../redux/slices/userExtraInfoSlice';
import { timeout } from '../../../utils/threading';
import { NextButton, PrevButton } from '../../components/forms/Button';
import ButtonContainer from '../../components/forms/ButtonContainer';
import FormGroup from '../../components/forms/FormGroup';
import AarogyaSetuApp from './components/AarogyaSetuApp';
import ConfirmedPatient from './components/ConfirmedPatient';
import Occupation from './components/Occupation';
import PatientCategory from './components/PatientCategory';
import ProfileHeader from './components/ProfileHeader';

class UserProfileExtra extends PureComponent {
  // for tab to next fields
  formId = 'frmUserExtraInfo';

  async componentDidMount() {
    await timeout(1);
    scrollToTop();
  }

  onClickPrev = (e) => {
    e.preventDefault();
    goToUserProfilePage();
  };

  onClickNext = (e) => {
    e.preventDefault();
    this.props.submitUserExtraInfo();
  };

  renderFooter() {
    return (
      <ButtonContainer>
        <PrevButton onClick={this.onClickPrev}>Back</PrevButton>
        <NextButton onClick={this.onClickNext}>Continue</NextButton>
      </ButtonContainer>
    );
  }

  renderSections() {
    const { user } = this.props;
    return [
      <PatientCategory key="patient-category" value={user.patientCategory} />,
      <Occupation key="occupation" value={user.occupation} />,
      <AarogyaSetuApp key="setyapp" value={user.aarogyaSetuAppDownloaded} />,
      <ConfirmedPatient
        key="confirmed-patient"
        value={user.contactWithLabConfirmedPatient}
      />,
    ];
  }

  render() {
    const formProps = {
      id: this.formId,
      onChange: this.props.updateUserExtraInfo,
      onEnter: this.focusNextField,
      fadeIn: true,
    };
    return (
      <>
        <ProfileHeader />
        <FormGroup {...formProps}>{this.renderSections()}</FormGroup>
        {this.renderFooter()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: getUserExtraInfo(state),
});

const mapDispatchToProps = {
  updateUserExtraInfo,
  submitUserExtraInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileExtra);
