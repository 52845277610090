import React from 'react';
import InputBox from '../InputBox';

export default function EmailInput(props) {
  return (
    <InputBox
      name="email"
      label="Email"
      type="email"
      placeholder="user1@gmail.com"
      maxLength="48"
      {...props}
    />
  );
}
