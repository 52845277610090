import styled, { css } from 'styled-components';

const primary = css`
  color: #664849;
  letter-spacing: 0;
`;

const secondary = css`
  color: white;
  letter-spacing: 0;
`;

const widget = css`
  color: #999;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 0;
`;

export const fonts = {
  primary,
  secondary,
  widget,
};

const h1 = css`
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.3;
`;

const h2 = css`
  color: #302119;
  font-size: 1.2rem;
  font-style: italic;
  font-weight: 500;
  line-height: 1.25;
`;

const text = css`
  font-size: 1.38rem;
  letter-spacing: 0;
  line-height: 1.3;
`;

const smallText = css`
  font-size: 1.15rem;
  font-weight: bold;
  line-height: 1.2;
`;

const smallerText = css`
  font-size: 1.15rem;
  letter-spacing: 0;
  line-height: 1.23;
`;

const buttonText = css`
  font-size: 1.23rem;
  font-weight: bold;
  line-height: 1.1875;
  text-align: center;
`;

const input = css`
  ${primary};
  font-size: 1.23rem;
  font-weight: bold;
  color: #4e2d37;
`;

export const textStyles = {
  h1,
  h2,
  text,
  smallText,
  buttonText,
  input,
};

export const HeaderText = styled.h1`
  ${primary};
  ${h1};
`;

export const Title = styled.p`
  ${primary};
  ${h1};
`;

export const Description = styled.p`
  ${primary};
  ${h2};
`;

export const Text = styled.p`
  ${primary};
  ${text};
`;

export const SmallText = styled.p`
  ${primary};
  ${smallText};
`;

export const SmallerText = styled.p`
  ${primary};
  ${smallerText};
`;
