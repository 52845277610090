import { useLocation } from '@reach/router';
import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import RestartIcon from '../../../assets/images/restart.svg';
import { showStartOver } from '../../../helpers/router';
import { startOver } from '../../../redux/actions';
import { Logo } from '../../components/images/Logo';
import { PageContainer } from '../../components/layout/PageContainer';
import { showStartOverPopup } from '../../modal/popup';

export default function Header() {
  const location = useLocation();
  const show = showStartOver(location);
  const dispatch = useDispatch();
  const handleStartOver = (e) => {
    e.preventDefault();
    showStartOverPopup(() => {
      dispatch(startOver());
    });
  };

  return (
    <PageContainer className="main-header">
      <Logo />
      {show && (
        <StartOver id="startOver" onClick={handleStartOver}>
          Start Over
        </StartOver>
      )}
    </PageContainer>
  );
}

//
// Styled Components
//

const StartOver = styled.button.attrs(() => ({
  type: 'button',
}))`
  position: absolute;
  right: 32px;
  top: 50%;
  color: #cf7b99;
  background: url(${RestartIcon}) left center no-repeat;
  background-size: 15px;
  height: 20px;
  border: 0;
  display: block;
  margin: -5px 0 0;
  text-align: left;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.26;
  padding-left: 22px;
  padding-right: 0;
  &:active,
  &:hover {
    background-color: transparent;
    background-size: 15px;
    opacity: 0.75;
  }
`;
