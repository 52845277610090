import axios from 'axios';

const GCS_BUCKET = process.env.REACT_APP_GCS_BUCKET;
const VISION_API = process.env.REACT_APP_GCV_API;
const CONTENT_TYPE = 'image/jpeg';

// @gcs bucket props
export const createBucketProps = (
  folder,
  filePrefix,
  contentType = CONTENT_TYPE,
) => ({
  bucket: GCS_BUCKET,
  folder,
  filePrefix,
  contentType,
});

// @gcs upload image
export const uploadImageToGCS = async (
  url,
  imageSrc,
  contentType = CONTENT_TYPE,
) => {
  const headers = { 'Content-Type': contentType };
  const buffer = Buffer.from(stripDataUrl(imageSrc), 'base64');
  return axios.put(url, buffer, { headers });
};

// @gcf: covid19-analyze-image
export const analyzeImageWithGoogleVision = async (tokens, imageSrc) => {
  const { accessToken } = tokens;
  const headers = {
    'Content-Type': 'application/json',
    AccessToken: `${accessToken}`,
  };
  const url = `${VISION_API}/covid19-analyze-image`;
  const data = { imageBytes: stripDataUrl(imageSrc) };
  return axios.post(url, data, { headers }).then((res) => res.data);
};

const stripDataUrl = (imageSrc) =>
  imageSrc.replace(/^data:image\/\w+;base64,/, '');
