import moment from 'moment';
import React, { PureComponent } from 'react';
import { DatePicker } from 'react-rainbow-components';
import styled from 'styled-components';
import withInputContainer from '../../hoc/withInputContainer';
import { inputCss } from '../../layout';

const MAX_DATE = moment().subtract(10, 'year').toDate();
const MIN_DATE = moment().subtract(120, 'year').toDate();

export class AgePicker extends PureComponent {
  name = 'birthDate';

  id = 'birthDate-picker';

  inputBoxName = 'age';

  maxDate() {
    return MAX_DATE;
  }

  minDate() {
    return MIN_DATE;
  }

  defaultDate() {
    return '2000-01-01';
  }

  onChange = (date) => {
    if (this.props.onChange) {
      const { name } = this;
      const value = moment(date).format();
      this.props.onChange({ name, value });
    }
  };

  render() {
    const { value } = this.props;
    const _value = value || this.defaultDate();
    const date = moment(_value).toDate();
    const className = value ? 'date-picker' : 'date-picker non-select';
    return (
      <Container>
        <DatePicker
          id={this.id}
          name={this.inputBoxName}
          className={className}
          value={date}
          onChange={this.onChange}
          formatStyle="medium"
          locale="en-IN"
          hideLabel
          minDate={this.minDate()}
          maxDate={this.maxDate()}
        />
      </Container>
    );
  }
}

export default withInputContainer(AgePicker, {
  label: 'Age',
});

//
// Styled Components
//

const Container = styled.div`
  ${inputCss};
  padding-right: 0;
  padding-left: 0;

  > select {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
  }
`;
