import { takeLatest } from 'redux-saga/effects';
import {
  completeTest,
  processImage,
  submitTestResult,
} from '../../actions/index';
import {
  handleCompleteTest,
  handleProcessImage,
  handleSubmitTestingResult,
} from './handlers';

export function* processImageSaga() {
  yield takeLatest(processImage.type, handleProcessImage);
}

export function* submitTestingResultSaga() {
  yield takeLatest(submitTestResult.type, handleSubmitTestingResult);
}

export function* completeTestSaga() {
  yield takeLatest(completeTest.type, handleCompleteTest);
}
