import { isEmpty } from 'lodash';

export const disableWindowScroll = () => {
  document.body.style.overflow = 'hidden';
};

export const enableWindowScroll = () => {
  document.body.style.overflow = 'auto';
};

export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

export const addClassNameToBody = (name) => {
  document.body.classList.add(name);
};

export const removeClassNameFromBody = (name) => {
  document.body.classList.remove(name);
};

export const setAppBackground = (report = true) => {
  const className = 'report';
  if (report) {
    addClassNameToBody(className);
  } else {
    removeClassNameFromBody(className);
  }
};

export const updateMultiAnswers = (values = [], value) => {
  if (value === 'none') {
    return [value];
  }
  let _values = values;
  const ndx = _values.indexOf(value);
  if (ndx < 0) {
    _values = _values.filter((v) => v !== 'none');
    _values.push(value);
  } else {
    _values.splice(ndx, 1);
  }
  return _values;
};

export const hasEmptyValue = (values) =>
  Object.values(values).some((value) => isEmpty(value));

export const isIndia = (code) => code === 'IN';
