import React from 'react';
import styled from 'styled-components';

export default function Button({ children, className, ...rest }) {
  let btnClassName = 'primary';
  if (className) {
    btnClassName += ` ${className}`;
  }

  return (
    <button type="button" className={btnClassName} {...rest}>
      {children}
    </button>
  );
}

export const PrevButton = Button;

export const NextButton = (props) => <Button className="inverted" {...props} />;

export const IconButton = styled(Button).attrs(() => ({
  className: 'icon-button',
}))``;
