/* eslint react/no-danger: 0 */
import React from 'react';
import alertConfirm from 'react-alert-confirm';
import 'react-alert-confirm/dist/index.css';
import { renderToString } from 'react-dom/server';
import { registerLocationCallback } from '../../helpers/location';
import './index.css';

export const showAlertConfirm = (options) => {
  alertConfirm(options);
};

export const alert = (text = '', onOk = null, options = {}) => {
  const content = description(text);
  const footer = (dispatch) => alertFooter(dispatch, options);
  showAlertConfirm({ type: 'alert', content, footer, onOk });
};

export const confirm = (
  text = '',
  onOk = null,
  onCancel = null,
  options = {},
) => {
  const content = description(text);
  const footer = (dispatch) => confirmFooter(dispatch, options);
  showAlertConfirm({ content, footer, onOk, onCancel });
};

//
// helper functions
//

const description = (text) => <p className="confirm-desc">{text}</p>;

const button = (text, onClick, inverted = false) => {
  const className = inverted ? ' inverted' : '';
  return (
    <button type="button" className={`primary${className}`} onClick={onClick}>
      {text}
    </button>
  );
};

const createFooter = (dispatch, footerContent = null) => {
  registerLocationCallback(() => {
    dispatch('cancel');
  });
  return typeof footerContent === 'function' ? footerContent() : null;
};

export const alertFooter = (dispatch, options = {}) => {
  const { btnText = 'OK' } = options;
  return createFooter(dispatch, () => {
    const yesButton = button(btnText, () => dispatch('ok'));
    return <div className="ss-alert-footer">{yesButton}</div>;
  });
};
const confirmFooter = (dispatch, options = {}) => {
  const { btnYesText = 'Yes', btnNoText = 'No' } = options;
  return createFooter(dispatch, () => {
    const yesButton = button(btnYesText, () => dispatch('ok'));
    const noButton = button(btnNoText, () => dispatch('cancel'), true);
    return (
      <div className="ss-confirm-footer">
        {yesButton}
        {noButton}
      </div>
    );
  });
};

export function toDomElement(Component, className = {}) {
  const html = renderToString(<Component />);
  return (
    <div className={className} dangerouslySetInnerHTML={{ __html: html }} />
  );
}
