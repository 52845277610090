import { createSlice } from '@reduxjs/toolkit';

export const loadSlice = createSlice({
  name: 'app',
  initialState: {
    loading: false,
    message: 'loading...',
    progressBar: false,
  },
  reducers: {
    showLoader: (state, { payload }) => ({
      ...state,
      loading: true,
      ...payload,
    }),
    hideLoader: (state) => {
      state.loading = false;
      state.progressBar = false;
    },
  },
});

export const { showLoader, hideLoader } = loadSlice.actions;

export default loadSlice.reducer;
