import React from 'react';
import SingleSelection from '../../components/forms/SingleSelection';

const data = {
  title: 'Status',
  options: [
    { label: 'Symptomatic', value: 'Symptomatic' },
    { label: 'Asymptomatic', value: 'Asymptomatic' },
  ],
};

export default function Status({ value, ...restProps }) {
  return (
    <SingleSelection
      name="status"
      data={data}
      selectedValue={value}
      {...restProps}
    />
  );
}
