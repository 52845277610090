import React from 'react';
import styled from 'styled-components';
import { fonts, textStyles } from '../text';

export default (WrappedComponent, passProps = {}) =>
  (props) => {
    const {
      label,
      labelStyle,
      forwardedRef,
      className = 'input-box',
      ...rest
    } = props;
    return (
      <Container className={className}>
        <Label style={labelStyle}>{label || passProps.label}</Label>
        <WrappedComponent {...rest} ref={forwardedRef} />
      </Container>
    );
  };

//
// Styled components
//

const Container = styled.div`
  position: relative;
  height: 47px;
  display: block;
  border-bottom: 1px solid #d7d6d1;
`;

const Label = styled.label`
  position: absolute;
  left: 10px;
  ${fonts.primary};
  ${textStyles.input};
  font-weight: 400;
  color: #aaaaaa;
  line-height: 47px;
  width: 50px;
`;
