import React, { useEffect } from 'react';
import styled from 'styled-components';
import { isMobileDevice } from '../../../utils/check';
import { fixedDiv } from '../../components/layout';

export default function PortraitModeOnly() {
  useEffect(() => {
    registerOnOrientationChangeEvent();
    return () => {
      removeOnOrientationChangeEvent();
    };
  }, []);

  return (
    <Container className="portrait-mode">
      <Text>Please rotate your device!</Text>
    </Container>
  );
}

const registerOnOrientationChangeEvent = () => {
  if (!isMobileDevice()) {
    return;
  }
  window.onorientationchange = () => {
    const rotated = window.orientation !== 0;
    document.getElementById('portrait-only').style.display = rotated
      ? 'block'
      : 'none';
    document.getElementById('root').style.visibility = rotated
      ? 'hidden'
      : 'visible';
  };
  window.onorientationchange();
};

const removeOnOrientationChangeEvent = () => {
  if (isMobileDevice()) {
    window.onorientationchange = null;
  }
};

//
// Styled Components
//
const Container = styled.div`
  ${fixedDiv};
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Text = styled.h1`
  font-size: 28px;
  margin-top: -100px;
`;
