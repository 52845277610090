import { createSlice } from '@reduxjs/toolkit';
import { updateMultiAnswers } from '../../helpers';

export const healthSlice = createSlice({
  name: 'health',
  initialState: {
    status: '',
    symptoms: [],
    symptomsDate: '',
    medicalConditions: [],
  },
  reducers: {
    updateHealthCondition: (state, { payload }) => {
      const { name: field, value } = payload;
      if (field === 'medicalConditions' || field === 'symptoms') {
        const values = [...state[field]];
        state[field] = updateMultiAnswers(values, value);
        return;
      }
      state[field] = value;
      if (field === 'status' && value === 'Asymptomatic') {
        state.symptoms = [];
        state.symptomsDate = '';
      }
    },
  },
});

export const { updateHealthCondition } = healthSlice.actions;

export default healthSlice.reducer;
