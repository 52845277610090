import React from 'react';
import styled from 'styled-components';
import { NextButton, PrevButton } from '../../components/forms/Button';
import ButtonContainer from '../../components/forms/ButtonContainer';
import OverlayGuidance from './OverlayGuidance';

export default function Preview({ image, onRetake, onProceed }) {
  const renderFooter = () => (
    <ButtonContainer box={false}>
      <PrevButton onClick={onRetake}>Retake Photo</PrevButton>
      <NextButton onClick={onProceed}>Proceed</NextButton>
    </ButtonContainer>
  );

  return (
    <>
      <Image src={image} alt="preview" />
      <OverlayGuidance renderFooter={renderFooter} />
    </>
  );
}

//
// Styled Components
//

const Image = styled.img`
  width: 100%;
  height: 100%;
`;
