import React, { PureComponent } from 'react';
import FadeIn from 'react-fade-in';
import { timeout } from '../../../utils/threading';

export default class FadeInView extends PureComponent {
  state = { check: null, fadeIn: false, dataField: 'data' };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { dataField } = prevState;
    const check = nextProps[dataField];
    if (check !== prevState.check) {
      return { check, fadeIn: true };
    }
    return null;
  }

  async componentDidMount() {
    await timeout(1);
    this.setState({ fadeIn: false });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (!prevState.fadeIn && this.state.fadeIn) {
      this.setState({ fadeIn: false });
    }
  }

  // @abstract
  renderComponent(/* props */) {
    return null;
  }

  render() {
    if (this.state.fadeIn) {
      return null;
    }
    const { onRenderComplete, ...restProps } = this.props;
    return (
      <FadeIn onComplete={onRenderComplete}>
        {this.renderComponent(restProps)}
      </FadeIn>
    );
  }
}
