import React from 'react';
import styled, { css } from 'styled-components';
import { Box } from '../layout';

export default function ButtonContainer({
  children,
  className = null,
  box = true,
}) {
  const len = children.length || 1;
  const width = `${Math.floor(100 / len)}%`;
  const style = { width };
  const Component = box ? BoxContainer : Container;
  return (
    <Component className={className}>
      {React.Children.map(children, (element) =>
        React.cloneElement(element, {
          ...element.props,
          style,
        }),
      )}
    </Component>
  );
}

//
// Styled Components
//

const containerCss = css`
  text-align: center;
  display: flex;

  button {
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const Container = styled.div`
  position: relative;
  padding: 24px;
  display: block;
  ${containerCss}
`;

const BoxContainer = styled(Box)`
  ${containerCss};
  padding: 24px;
`;
