import { ROUTER } from './router';

// Make sure the images are in public/assets/media folder
export const getImagePath = (filename) =>
  `${ROUTER.ROOT_DIR}assets/media/${filename}`;

export const logo = {
  src: getImagePath('sensing_self_logo.png'),
  title: 'SensingSelf',
};

export const testStepImages = (type) => {
  const t = type.toLowerCase();
  if (t === 'nasal swab') {
    return nasalSwabTestStepImages;
  }
  return sputumTestStepImages;
};

const nasalSwabTestStepImages = [
  { id: 1, src: getImagePath('nasal_step_1.png'), title: '' },
  { id: 2, src: getImagePath('nasal_step_2.png'), title: '' },
  { id: 3, src: getImagePath('nasal_step_3.png'), title: '' },
  { id: 4, src: getImagePath('nasal_step_4.png'), title: '' },
  { id: 5, src: getImagePath('nasal_step_5.png'), title: '' },
  { id: 6, src: getImagePath('nasal_step_6.png'), title: '' },
  { id: 7, src: getImagePath('sputum_step_5.png'), title: '' },
  { id: 8, src: getImagePath('sputum_step_6.png'), title: '' },
  { id: 9, src: getImagePath('sputum_step_7.png'), title: '' },
  { id: 10, src: getImagePath('sputum_step_8.png'), title: '' },
];

const sputumTestStepImages = [
  { id: 1, src: getImagePath('sputum_step_1.png'), title: '' },
  { id: 2, src: getImagePath('sputum_step_2.png'), title: '' },
  { id: 3, src: getImagePath('sputum_step_3.png'), title: '' },
  { id: 4, src: getImagePath('sputum_step_4.png'), title: '' },
  { id: 5, src: getImagePath('sputum_step_5.png'), title: '' },
  { id: 6, src: getImagePath('sputum_step_6.png'), title: '' },
  { id: 7, src: getImagePath('sputum_step_7.png'), title: '' },
  { id: 8, src: getImagePath('sputum_step_8.png'), title: '' },
];
