import { select } from 'redux-saga/effects';
import { goToNext } from '../../../helpers/router';
import { showErrorPopup } from '../../../views/modal/popup';
import {
  getUserProfile,
  hasCompleteUserExtraInfo,
} from '../../selectors/index';
import { validateUserProfile } from '../../validators';

export function* handleSubmitUserProfile() {
  const user = yield select(getUserProfile);
  const errors = validateUserProfile(user);
  if (errors) {
    showValidateError(errors);
    return;
  }
  goToNext('USER_PROFILE');
}

export function* handleSubmitUserExtraInfo() {
  const allAnswered = yield select(hasCompleteUserExtraInfo);
  if (!allAnswered) {
    showErrorPopup('Please answer all the questions.');
    return;
  }
  goToNext('USER_PROFILE_EXTRA');
}

const showValidateError = (errors) => {
  // for now, just show the first error.
  const [, error] = Object.entries(errors)[0];
  showErrorPopup(error[0]);
};
