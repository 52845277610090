import { useLocation } from '@reach/router';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { setAppBackground } from '../../../helpers';
import { isHealthReportPage } from '../../../helpers/router';
import { appSetup } from '../../../redux/actions';
import { getLoaderData } from '../../../redux/selectors';
import { PageContainer } from '../../components/layout/PageContainer';
import Disclaimer from '../../components/popup/Disclaimer';
import Loader from '../../components/widgets/Loader';
import Footer from './Footer';
import Header from './Header';

export default function App(props) {
  const location = useLocation();
  const { loading, message, progressBar } = useSelector(getLoaderData);
  const dispatch = useDispatch();
  const reportPage = isHealthReportPage(location);
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const onClickFooterLink = (link) => {
    if (link === 'disclaimer') {
      setShowDisclaimer(true);
    }
  };
  const closeDisclaimer = () => {
    setShowDisclaimer(false);
  };

  useEffect(async () => {
    dispatch(appSetup({ location }));
  }, []);

  setAppBackground(reportPage);

  return (
    <Container>
      <Header />
      <PageContainer>{props.children}</PageContainer>
      <Footer onClickLink={onClickFooterLink} />
      <Disclaimer active={showDisclaimer} onClose={closeDisclaimer} />
      <Loader active={loading} message={message} progressBar={progressBar} />
    </Container>
  );
}

//
// Styled Components
//

const Container = styled.div`
  position: relative;
`;
