import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { customAlphabet } from 'nanoid';
import { goToTakePhoto } from '../../helpers/router';

const createRandomId = () => {
  const nanoid = customAlphabet('1234567890ABCDEFJYSTUM', 12);
  return nanoid();
};

export const testingSlice = createSlice({
  name: 'testing',
  initialState: {
    timerStartedTime: 0,
    imageProcessed: false,
    imageUploaded: false,
    testingId: '',
    testingType: 's2covid',
    testingKit: 'Antigen Test kit',
    testingTime: 0,
    testingTimeStr: '',
    result: null,
    resultImage: {},
    prediction: {},
    submitted: false,
  },
  reducers: {
    createTestingId: (state) => {
      state.testingId = createRandomId();
    },
    timerStarted: (state, { payload }) => {
      const { startedTime } = payload;
      state.testingId = createRandomId();
      state.result = null;
      state.resultImage = {};
      state.prediction = {};
      state.imageProcessed = false;
      state.imageUploaded = false;
      state.timerStartedTime = startedTime;
      state.submitted = false;
    },
    timerSkipped: (state) => {
      state.startedTime = 0;
      goToTakePhoto();
    },
    imageProcessed: (state) => {
      state.imageProcessed = true;
      state.imageUploaded = true;
    },
    imageProcessFailed: (state) => {
      state.imageProcessed = false;
      state.imageUploaded = false;
    },
    updateTestResult: (state, { payload }) => {
      const { prediction, result, resultImage } = payload;
      state.result = result;
      state.prediction = prediction;
      state.resultImage = resultImage;
      const testingTime = moment();
      state.testingTime = testingTime.valueOf();
      state.testingTimeStr = testingTime.format();
    },
    testResultSubmitted: (state) => {
      state.submitted = true;
    },
  },
});

export const {
  createTestingId,
  timerStarted,
  timerSkipped,
  imageProcessed,
  imageProcessFailed,
  updateTestResult,
  testResultSubmitted,
} = testingSlice.actions;

export default testingSlice.reducer;
