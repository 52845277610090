import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { isMobileDevice } from '../../../utils/check';
import withInputContainer from '../hoc/withInputContainer';
import { inputCss } from '../layout';
import { textStyles } from '../text';

export class InputBox extends PureComponent {
  constructor(props) {
    super(props);
    const { type } = this.props;
    this.inputType = type === 'number' && !isMobileDevice() ? 'text' : type;
  }

  onChange = (event) => {
    event.preventDefault();
    if (!this.props.onChange || event.target.value === this.props.value) {
      return;
    }
    const { name } = this.props;
    const { value } = event.target;
    this.props.onChange({ name, value });
  };

  onInput = (event) => {
    if (this.props.max) {
      const { max } = this.props;
      const _value = parseFloat(event.target.value);
      if (_value > max) {
        event.target.value = max;
      }
    }
    if (this.inputType !== 'number') {
      return;
    }
    const { maxLength = 999 } = this.props;
    if (event.target.value.length > maxLength) {
      event.target.value = this.props.value;
    }
  };

  onKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.onEnter();
      return true;
    }
    if (this.inputType !== 'number') {
      return true;
    }
    const allowKeys = ['Backspace', 'Tab'];
    if (allowKeys.indexOf(event.key) >= 0) {
      return true;
    }
    const { maxLength = 999, value } = this.props;
    return value.length < maxLength;
  };

  onEnter = async () => {
    if (this.props.onEnter) {
      const { name, id } = this.props;
      this.props.onEnter({ id, name });
    }
  };

  render() {
    const { onChange, onEnter, type, ...rest } = this.props;
    return (
      <Input
        {...rest}
        type={this.inputType}
        onChange={this.onChange}
        onInput={this.onInput}
        onKeyDown={this.onKeyDown}
      />
    );
  }
}

export default withInputContainer(InputBox);

//
// Styled Components
//

const Input = styled.input.attrs(({ type }) => ({
  type: type || 'text',
}))`
  ${textStyles.input};
  ${inputCss};
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
`;
