import { combineReducers, configureStore } from '@reduxjs/toolkit';
import throttle from 'lodash/throttle';
import createSagaMiddleware from 'redux-saga';
import { rootSaga } from '../sagas';
import appSlice from '../slices/appSlice';
import authSlice from '../slices/authSlice';
import healthSlice from '../slices/healthSlice';
import loaderSlice from '../slices/loaderSlice';
import sampleSlice from '../slices/sampleSlice';
import testingSlice from '../slices/testingSlice';
import userExtraInfoSlice from '../slices/userExtraInfoSlice';
import userSlice from '../slices/userSlice';
import vaccinationSlice from '../slices/vaccinationSlice';
import { loadState, saveState } from './localStorage';

//
// reducers
//
const rootReducer = combineReducers({
  app: appSlice,
  auth: authSlice,
  loader: loaderSlice,
  testing: testingSlice,
  user: userSlice,
  userExtraInfo: userExtraInfoSlice,
  sample: sampleSlice,
  health: healthSlice,
  vaccination: vaccinationSlice,
});

// resettable root reducer
const reducer = (state, action) => {
  const types = ['emergency/call', 'app/startOver', 'testing/completeTest'];
  if (types.indexOf(action.type) >= 0) {
    return rootReducer(undefined, action);
  }
  return rootReducer(state, action);
};

//
// middleWares
//
const sagaMiddleware = createSagaMiddleware();

const middleWares = [sagaMiddleware];

if (process.env.NODE_ENV === 'development') {
  const { logger } = require('redux-logger');
  middleWares.push(logger);
}

//
// configure store
//
const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: false }).concat(middleWares),
  preloadedState: loadState(),
});

//
// save state
//
store.subscribe(
  throttle(() => {
    const { app, loader, ...slices } = store.getState();
    saveState({ ...slices });
  }, 1000),
);

//
// start saga
//
sagaMiddleware.run(rootSaga);

export default store;
