import React from 'react';
import styled from 'styled-components';
import { fade, fixedDiv } from '../layout';
import { FadeView } from '../layout/FadeView';

export default (WrappedComponent, passProps) => {
  const { duration = 1 /* seconds */ } = passProps || {};

  class FadeOverlay extends FadeView {
    renderComponent(activate, className, restProps) {
      return (
        <Container
          ref={this.setContainerRef}
          className={className}
          duration={duration}
        >
          <WrappedComponent {...restProps} activate={activate} />
        </Container>
      );
    }
  }
  return FadeOverlay;
};

const Container = styled.div`
  ${fixedDiv};
  ${fade};
  background: rgba(0, 0, 0, 0.5);
`;
