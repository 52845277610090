import React from 'react';
import InputBox from '../../../components/forms/InputBox';
import { HeaderBox } from '../../../components/layout';

export default function PassportInput(props) {
  return (
    <HeaderBox theme="white">
      <InputBox name="passportNumber" label="Passport number" {...props} />
    </HeaderBox>
  );
}
