import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { submitTestResult } from '../../../redux/actions';
import Button from '../../components/forms/Button';
import ButtonContainer from '../../components/forms/ButtonContainer';
import { HeaderBox } from '../../components/layout/index';
import { Description, Title } from '../../components/text';
import ResultImage from './ResultImage';

class TestResult extends PureComponent {
  onClickNext = () => {
    this.props.submitTestResult();
  };

  render() {
    const { resultImage } = this.props;
    return (
      <>
        <Container>
          <HeaderBox>
            <Title>Test Result</Title>
            <Description>Please tap on button to see the result.</Description>
          </HeaderBox>
          <ResultImage labelText="Result" resultImage={resultImage} />
        </Container>
        <ButtonContainer>
          <Button onClick={this.onClickNext} className="center btn-result">
            See Test Result
          </Button>
        </ButtonContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  resultImage: state.app.resultImage,
});

const mapDispatchToProps = {
  submitTestResult,
};

export default connect(mapStateToProps, mapDispatchToProps)(TestResult);

//
// Styled Components
//

const Container = styled.div``;
