import { isIndia } from '../helpers';
import { getGenderText } from '../helpers/strings';
import { getCountryName } from '../views/components/forms/selectors/CountryPicker';

export const mapToUserInfo = ({
  gender,
  nationality,
  aadharNumber,
  passportNumber,
  ...restProps
}) => {
  const india = isIndia(nationality);
  const id = {
    label: india ? 'Aadhar No.' : 'Passport No.',
    value: india ? formatAadharNumber(aadharNumber) : passportNumber,
  };
  return {
    ...restProps,
    gender: getGenderText(gender),
    nationality: getCountryName(nationality),
    id,
  };
};

const formatAadharNumber = (number) =>
  number.replace(/[^0-9], ''/).replace(/^(\d{4})(\d{4})(.+)$/, '$1 $2 $3');
