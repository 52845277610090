import { isEmpty } from 'lodash';
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { getGenderText } from '../../../../helpers/strings';
import withInputContainer from '../../hoc/withInputContainer';
import { inputCss } from '../../layout';
import { textStyles } from '../../text';

const options = ['female', 'male', 'trans'];

class GenderPicker extends PureComponent {
  name = 'gender';

  onChange = (event) => {
    event.preventDefault();
    this.update(event.target.value);
  };

  onClick = (event) => {
    event.preventDefault();
    if (isEmpty(this.props.value)) {
      this.update(options[0]);
    }
  };

  update = (value) => {
    if (this.props.onChange) {
      const { name } = this;
      this.props.onChange({ name, value });
    }
  };

  renderOptions() {
    return options.map((option, index) => {
      const key = `${index}`;
      const label = getGenderText(option);
      return (
        <option key={key} value={option}>
          {label}
        </option>
      );
    });
  }

  render() {
    const { name } = this;
    const { value } = this.props;

    const text = getGenderText(value) || 'Select';
    const className = value ? 'gender-picker' : 'gender-picker non-select';
    return (
      <Container className={className}>
        <Text>{text}</Text>
        <select
          name={name}
          value={value}
          onChange={this.onChange}
          onClick={this.onClick}
        >
          {this.renderOptions()}
        </select>
      </Container>
    );
  }
}

export default withInputContainer(GenderPicker, {
  label: 'Gender',
});

//
// Styled Components
//

const Container = styled.div`
  ${inputCss};
  padding-right: 0;
  padding-left: 0;

  > select {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
  }
`;

const Text = styled.span`
  ${textStyles.input};
  ${inputCss};
`;
