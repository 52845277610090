import React from 'react';
import SingleSelection from '../../../components/forms/SingleSelection';

const data = {
  title: 'Occupation',
  options: [
    { label: 'Health Care Worker', value: 'HCW' },
    { label: 'Police', value: 'POLICE' },
    { label: 'Sanitation', value: 'SNTN' },
    { label: 'Security Guards', value: 'SECG' },
    { label: 'Others', value: 'OTHER' },
  ],
};

export default function Occupation({ value, ...restProps }) {
  return (
    <SingleSelection
      name="occupation"
      data={data}
      selectedValue={value}
      {...restProps}
    />
  );
}
