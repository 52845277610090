import moment from 'moment';
import { AgePicker } from '../../components/forms/selectors/AgePicker';
import withHeaderBox from '../../components/hoc/withHeaderBox';
import withInputContainer from '../../components/hoc/withInputContainer';

class VaccineDoseDate extends AgePicker {
  constructor(props) {
    super(props);
    const { index, name } = this.props;
    this.name = name;
    this.id = `vaccineDose${index}-picker`;
    this.inputBoxName = `doseDate${index}`;
  }

  maxDate() {
    return moment().toDate();
  }

  minDate() {
    if (this.props.minDate) {
      return moment(this.props.minDate).toDate();
    }
    return moment('2021-01-01').toDate();
  }

  defaultDate() {
    return '2021-01-01';
  }
}

export default withHeaderBox(withInputContainer(VaccineDoseDate));
