import validate from 'validate.js';
import { isIndia } from '../../../helpers';

const { isEmpty } = validate;

//
// user profile constraints
//
export default {
  name: {
    presence: { allowEmpty: false, message: 'Please enter your name.' },
    length: {
      minimum: 4,
      message: 'Name must be at least 4 characters.',
    },
  },
  gender: {
    presence: { allowEmpty: false, message: 'Please select your gender.' },
  },
  birthDate: {
    presence: { allowEmpty: false, message: 'Please enter your age.' },
  },
  mobile: {
    presence: true,
    format: {
      pattern: /^[+\- 0-9]+$/,
      message: 'Please enter a valid mobile number.',
    },
    length: (value) => {
      const [min, max] = [7, 16];
      const _value = value.replace(/[^0-9]/g, '');
      if (_value.length < min) {
        return {
          minimum: min,
          message: `Mobile number must be at least ${min} digits.`,
        };
      }
      if (_value.length > max) {
        return {
          maximum: max,
          message: 'Please check your mobile number.',
        };
      }
      return null;
    },
  },
  email: {
    email: (value) =>
      !isEmpty(value) ? { message: 'Please enter validate email.' } : false,
  },
  aadharNumber: (value, attributes) => {
    const { nationality } = attributes;
    if (!isIndia(nationality)) {
      return null;
    }
    return {
      presence: {
        allowEmpty: false,
        message: 'Please enter your aadhar number.',
      },
    };
  },
  passportNumber: (value, attributes) => {
    const { nationality } = attributes;
    if (isIndia(nationality)) {
      return null;
    }
    return {
      presence: {
        allowEmpty: false,
        message: 'Please enter your passport number.',
      },
    };
  },
  address: {
    presence: { allowEmpty: false, message: 'Please enter your address.' },
    length: {
      minimum: 10,
      message: 'Address must be at least 10 characters.',
    },
  },
  state: {
    numericality: {
      greaterThan: -1,
      message: 'Please select your state.',
    },
  },
  district: {
    numericality: {
      greaterThan: -1,
      message: 'Please select your district.',
    },
  },
  pincode: {
    presence: {
      allowEmpty: false,
      message: 'Please enter your pincode.',
    },
    format: {
      pattern: /^\d+$/,
    },
    length: {
      is: 6,
      message: 'Please enter valid pincode.',
    },
  },
};
