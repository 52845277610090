import React from 'react';
import { CountryPicker } from '../../../../components/forms/selectors/CountryPicker';
import withInputContainer from '../../../../components/hoc/withInputContainer';

// load states data
const states = require('../../../../../assets/data/LG_Directory.json');

class DistrictSelector extends CountryPicker {
  name = 'district';

  state = { stateCode: -1, districts: [] };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { stateCode } = nextProps;

    if (stateCode !== prevState.stateCode) {
      const filtered = states.filter((s) => `${s.code}` === `${stateCode}`);
      if (filtered.length > 0) {
        const { districts } = filtered[0];
        return { districts, stateCode };
      }
      return { stateCode };
    }
    return null;
  }

  componentDidMount() {
    this.autoPopulate();
  }

  componentDidUpdate() {
    this.autoPopulate();
  }

  autoPopulate() {
    if (this.state.districts.length === 1 && `${this.props.value}` === '-1') {
      const { name } = this;
      const { code: value } = this.state.districts[0];
      this.props.onChange({ name, value });
    }
  }

  getDistrictName = (code) => {
    const { districts } = this.state;
    const filtered = districts.filter((d) => `${d.code}` === `${code}`);
    return filtered.length > 0 ? filtered[0].name : '';
  };

  getText = () => {
    const { value } = this.props;
    return this.getDistrictName(value);
  };

  getFirstOptionValue = () => this.state.districts[0].code;

  hasValue = () => `${this.props.value}` !== '-1';

  renderOptions() {
    const { districts } = this.state;
    return districts.map((district, index) => {
      const key = `district-${index}`;
      const { name, code } = district;
      return (
        <option key={key} value={code}>
          {name}
        </option>
      );
    });
  }
}

export default withInputContainer(DistrictSelector, {
  label: 'District',
});
