import { isEmpty } from 'lodash';
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import withInputContainer from '../../../hoc/withInputContainer';
import { inputCss } from '../../../layout';
import { textStyles } from '../../../text';

// load country data
const countries = require('./resources/country.json');

export const getCountryName = (value) => {
  const filtered = countries.filter(({ code }) => code === value);
  return filtered.length > 0 ? filtered[0].name : '';
};

export class CountryPicker extends PureComponent {
  name = 'nationality';

  getText = () => {
    const { value } = this.props;
    return getCountryName(value);
  };

  getFirstOptionValue = () => countries[0].code;

  hasValue = () => !isEmpty(this.props.value);

  onChange = (event) => {
    this.update(event.target.value);
  };

  onClick = () => {
    if (!this.hasValue()) {
      const value = this.getFirstOptionValue();
      this.update(value);
    }
  };

  update = (value) => {
    if (this.props.onChange) {
      const { name } = this;
      this.props.onChange({ name, value });
    }
  };

  renderOptions() {
    return countries.map((country, index) => {
      const key = `${index}`;
      const { name, code } = country;
      return (
        <option key={key} value={code}>
          {name}
        </option>
      );
    });
  }

  render() {
    const { name } = this;
    const { value } = this.props;
    const text = this.getText();
    return (
      <Container>
        <Text className="select-label">{text}</Text>
        <select
          name={name}
          value={value}
          onChange={this.onChange}
          onClick={this.onClick}
        >
          {this.renderOptions()}
        </select>
      </Container>
    );
  }
}

export default withInputContainer(CountryPicker, {
  label: 'Nationality',
});

//
// Styled Components
//

const Container = styled.div`
  ${inputCss};
  padding-right: 0;
  padding-left: 0;

  > select {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
  }
`;

const Text = styled.span`
  ${textStyles.input};
  ${inputCss};
`;
