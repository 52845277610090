import { createSelector } from 'reselect';
import { hasEmptyValue } from '../../helpers';
import { validateUserProfile } from '../validators';

export const getAuthData = (state) => state.auth;

export const getLoaderData = (state) => state.loader;

export const getUserProfile = (state) => state.user;

export const getUserExtraInfo = (state) => state.userExtraInfo;

export const getSampleData = (state) => state.sample;

export const getSampleType = (state) => getSampleData(state).type;

export const getHealthCondition = (state) => state.health;

export const getVaccinationInfo = (state) => state.vaccination;

export const getTestingResult = (state) => state.testing;

export const getTestingId = (state) => state.testing.testingId;

export const getTestingType = (state) => state.testing.testingType;

export const hasProcessedImage = (state) => state.testing.imageProcessed;

export const hasSubmittedResult = (state) => state.testing.submitted;

export const isAppStarted = (state) => state.app.loaded;

export const getSubmitData = (state) => {
  const { app, auth, loader, ...rest } = state;
  return { ...rest };
};

//
// reselectors
//

export const hasCompleteUserProfile = createSelector(
  getUserProfile,
  (user) => !validateUserProfile(user),
);

export const hasCompleteUserExtraInfo = createSelector(
  getUserExtraInfo,
  (userExtraInfo) => !hasEmptyValue(userExtraInfo),
);

export const hasSymptoms = createSelector(
  getHealthCondition,
  (health) => health.symptoms.length > 0,
);
