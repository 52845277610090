import React from 'react';
import styled from 'styled-components';
import { Box as SSBox } from '../layout';
import { SmallerText, Text } from '../text';

export const withLabelBox = (WrappedComponent) => ({ labelText, ...props }) => (
  <LabelBox labelText={labelText} theme="default">
    <WrappedComponent {...props} />
  </LabelBox>
);

export const withStepContainer = (WrappedComponent) => ({ step, ...props }) => {
  const labelText = `Step ${step}`;
  return (
    <LabelBox labelText={labelText} theme="step">
      <WrappedComponent {...props} step={step} />
    </LabelBox>
  );
};

const LabelBox = ({ labelText, children, theme = 'step' }) => (
  <Box>
    <Label theme={theme}>{labelText}</Label>
    {children}
  </Box>
);

//
// Styled Components
//

const Box = styled(SSBox)`
  ${Text} {
    margin-bottom: 1.538rem;
  }
`;

const Label = styled(SmallerText)`
  font-weight: bold;
  margin-bottom: ${(props) => (props.theme === 'step' ? '1.538rem' : '0')};
`;
