import React from 'react';
import FadeIn from 'react-fade-in';
import { Box } from '../layout';

export default function FormGroup(props) {
  const {
    children,
    onChange,
    onEnter,
    id,
    className = '',
    fadeIn = false,
  } = props;
  const Container = fadeIn ? FadeIn : Box;
  return (
    <Container id={id} className={className}>
      {React.Children.map(
        children,
        (element) =>
          element &&
          React.cloneElement(element, { ...element.props, onChange, onEnter }),
      )}
    </Container>
  );
}
