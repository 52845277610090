import { all, fork } from 'redux-saga/effects';
import * as appSagas from './app';
import * as eventSagas from './event';
import * as loaderSagas from './loader';
import * as testingSagas from './testing';
import * as userSagas from './user';

export function* rootSaga() {
  yield all(
    [
      ...Object.values(appSagas),
      ...Object.values(eventSagas),
      ...Object.values(loaderSagas),
      ...Object.values(testingSagas),
      ...Object.values(userSagas),
    ].map(fork),
  );
}
