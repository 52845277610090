import moment from 'moment';
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import Button from '../../components/forms/Button';
import { withStepContainer } from '../../components/hoc/withLabelBox';
import { fonts, Text } from '../../components/text';
import { EVENT_START_TIMER } from '../../../redux/sagas/event/events';
import { subscribe, unsubscribe } from '../../../redux/sagas/event/emmiter';

const countDownMinutes = 15;

const formatTime = (num) => `${num}`.padStart(2, '0');

class Timer extends PureComponent {
  state = { started: false, minutes: countDownMinutes, seconds: 0 };

  timer = null;

  componentDidMount() {
    this.subscribeId = subscribe(EVENT_START_TIMER, () => this.onClick());
  }

  async componentDidUpdate() {
    if (!this.props.onTimerUp) {
      return;
    }
    const { started, minutes, seconds } = this.state;
    if (started && minutes === 0 && seconds === 0) {
      this.props.onTimerUp();
    }
  }

  componentWillUnmount() {
    this.clearTimer();
    unsubscribe(this.subscribeId);
  }

  clearTimer() {
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.from = null;
    this.to = null;
  }

  onTimerUp = () => {
    this.clearTimer();
    if (this.props.onTimerUp) {
      this.props.onTimerUp();
    }
  };

  onClick = (e) => {
    if (e) {
      e.preventDefault();
      e.target.disabled = true;
    }
    this.startTimer();
  };

  onClickTimerNumber = (e) => {
    e.preventDefault();
    if (process.env.REACT_APP_ENV === 'DEV' && this.state.started) {
      this.props.onClickTimerNumber();
    }
  };

  startTimer = () => {
    this.from = moment();
    this.to = moment().add(countDownMinutes, 'minutes');
    this.timer = setInterval(this.countDown, 1000);

    if (this.props.onTimerStarted) {
      this.props.onTimerStarted(this.from.valueOf());
    }
  };

  countDown = () => {
    this.to.subtract(1, 'seconds');
    const diff = this.to.diff(this.from) / 1000;
    const seconds = Math.floor(diff % 60);
    const minutes = Math.floor(diff / 60);
    if (minutes === 0 && seconds === 0) {
      this.clearTimer();
    }
    this.setState({ started: true, minutes, seconds });
  };

  render() {
    const { showTimerButton = true } = this.props;
    const { description } = this.props.data;
    const { minutes, seconds } = this.state;
    return (
      <>
        <Container>
          {description.map((desc) => (
            <Text key={desc}>{desc}</Text>
          ))}

          <CountDownTime onClick={this.onClickTimerNumber}>
            <Minutes>{formatTime(minutes)}</Minutes>
            <Colon>:</Colon>
            <Seconds>{formatTime(seconds)}</Seconds>
          </CountDownTime>

          {showTimerButton && (
            <Button onClick={this.onClick} className="center">
              Start Timer
            </Button>
          )}
        </Container>
      </>
    );
  }
}

export default withStepContainer(Timer);

//
// Styled Components
//

const Container = styled.div``;

const CountDownTime = styled.div`
  ${fonts.primary}
  font-size: 150px;
  letter-spacing: 3.74px;
  margin: 40px auto;

  @media (max-width: 450px) {
    font-size: 23vw;
    letter-spacing: 3px;
  }
`;

const Seconds = styled.span`
  width: 45%;
  display: inline-block;
  text-align: left;
`;

const Minutes = styled(Seconds)`
  text-align: right;
`;

const Colon = styled.span`
  width: 10%;
  display: inline-block;
  text-align: center;
`;
