import isEmpty from 'lodash/isEmpty';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import appData from '../../../config/data';
import { testStepImages } from '../../../config/images';
import {
  goToPrevious,
  goToTakePhoto,
  goToTestProcessStep,
  goToTimer,
  redirectToHome,
} from '../../../helpers/router';
import { emitEvent } from '../../../redux/actions';
import { getSampleType } from '../../../redux/selectors';
import { timerSkipped, timerStarted } from '../../../redux/slices/testingSlice';
import { parseIndex } from '../../../utils/number';
import { NextButton, PrevButton } from '../../components/forms/Button';
import ButtonContainer from '../../components/forms/ButtonContainer';
import { HeaderBox } from '../../components/layout/index';
import { Description, Title } from '../../components/text';
import { confirm } from '../../modal';
import Step from './Step';
import Timer from './Timer';
import { EVENT_START_TIMER } from '../../../redux/sagas/event/events';

const parseUrlParam = (param) => {
  if (isEmpty(param)) {
    return 0;
  }
  let step = param.replace(/[^0-9]/g, '');
  step = parseIndex(step);
  return step - 1;
};

class Tester extends PureComponent {
  state = { index: -1, timer: false, data: null };

  constructor(props) {
    super(props);
    Tester.data = appData.loadTestingProcess(this.props.sampleType);
    Tester.images = testStepImages(this.props.sampleType);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const slug = nextProps['*'];
    if (slug === 'timer' && prevState.timer) {
      return null;
    }
    if (slug === 'timer') {
      return { index: -1, data: null, timer: true };
    }
    const index = parseUrlParam(nextProps['*']);
    if (index < 0) {
      goToPrevious('TESTING_PROCESS');
      return null;
    }
    const { steps } = Tester.data;
    if (index >= steps.length) {
      redirectToHome();
      return null;
    }
    if (index !== prevState.index) {
      const data = steps[index];
      return { index, data, timer: false };
    }
    return null;
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.index !== this.state.index) {
      window.scrollTo(0, 0);
    }
  }

  getHeaderInfo() {
    if (this.state.timer) {
      return {
        title: 'Start Your Timer',
        description:
          'Once you are ready and finished with sample collection please immediately start your timer before taking a picture.',
      };
    }
    return {
      title: 'Start Testing Process',
      description:
        'Follow the instructions on the screen for Rapid Antigen Testing',
    };
  }

  getTestingProcessData = () => Tester.data;

  getPrevButtonLabel = () => {
    const { timer } = this.state;
    if (timer) {
      return 'Previous';
    }
    const { index } = this.state;
    return index > 0 ? 'Previous' : 'Back';
  };

  getNextButtonLabel = () => {
    const { timer } = this.state;
    return timer ? 'Start Timer' : 'Next';
  };

  onClickPrev = (e) => {
    e.preventDefault();
    const { steps } = Tester.data;
    const { index, timer } = this.state;
    const step = timer ? steps.length - 1 : index - 1;
    goToTestProcessStep(step);
  };

  onClickNext = (e) => {
    e.preventDefault();
    const { steps } = this.getTestingProcessData();
    if (this.state.timer) {
      this.onStartTimer(e);
    } else if (this.state.index === steps.length - 1) {
      goToTimer();
    } else {
      goToTestProcessStep(this.state.index + 1);
    }
  };

  onStartTimer = (e) => {
    e.target.disabled = true;
    this.props.emitEvent({ name: EVENT_START_TIMER });
    // confirm('Are you sure you want to skip?', () => this.props.timerSkipped());
  };

  onClickTimerNumber = () => {
    if (!this.clickCounter) {
      this.clickCounter = 0;
    }
    const clearTimerCount = () => {
      this.clickCounter = 0;
      this.clickTimer = null;
    };
    this.clickCounter += 1;
    if (!this.clickTimer) {
      this.clickTimer = setTimeout(clearTimerCount, 2000);
    }
    if (this.clickCounter < 5) {
      return;
    }
    if (this.clickTimer) {
      clearTimeout(this.clickTimer);
      clearTimerCount();
    }
    confirm('Are you sure you want to skip?', () => this.props.timerSkipped());
  };

  onTimerStarted = (startedTime) => {
    this.props.timerStarted({ startedTime });
  };

  onTimerUp = () => {
    goToTakePhoto();
  };

  renderStep() {
    const { data } = this.state;
    if (!data) {
      return null;
    }
    const { index } = this.state;
    const image = Tester.images[index];
    return (
      <Step
        data={data}
        image={image}
        step={index + 1}
        renderFooter={this.renderFooter}
      />
    );
  }

  renderTimer() {
    const { steps, timer } = this.getTestingProcessData();
    const step = steps.length;
    return (
      <Timer
        step={step + 1}
        data={timer}
        onTimerStarted={this.onTimerStarted}
        onTimerUp={this.onTimerUp}
        renderFooter={this.renderFooter}
        onRenderComplete={this.onRenderOptionComplete}
        showTimerButton={false}
        onClickTimerNumber={this.onClickTimerNumber}
      />
    );
  }

  renderFooter = () => {
    const prevBtnLabel = this.getPrevButtonLabel();
    const nextBtnLabel = this.getNextButtonLabel();
    return (
      <ButtonContainer key="tester-footer">
        <PrevButton onClick={this.onClickPrev}>{prevBtnLabel}</PrevButton>
        <NextButton onClick={this.onClickNext}>{nextBtnLabel}</NextButton>
      </ButtonContainer>
    );
  };

  render() {
    const { timer } = this.state;
    const { title, description } = this.getHeaderInfo();
    return (
      <>
        <Container className="page">
          <HeaderBox>
            <Title>{title}</Title>
            <Description>{description}</Description>
          </HeaderBox>
          {!timer && this.renderStep()}
          {timer && this.renderTimer()}
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  sampleType: getSampleType(state),
});

const mapDispatchToProps = {
  emitEvent,
  timerStarted,
  timerSkipped,
};

export default connect(mapStateToProps, mapDispatchToProps)(Tester);

//
// Styled Components
//

const Container = styled.div``;
