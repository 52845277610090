import axios from 'axios';
import md5 from 'blueimp-md5';
import moment from 'moment';
import { getAuthData } from '../redux/selectors';
import {
  createBucketProps,
  uploadImageToGCS,
  analyzeImageWithGoogleVision,
} from './gcloud';

const API_URL = process.env.REACT_APP_API_URL;
const CLIENT_ID = process.env.REACT_APP_API_CLIENT_ID;
const SOURCE = process.env.REACT_APP_SOURCE;

const config = {
  store: null,
  token: {
    idToken: null,
    expires: 0,
  },
};

const apiUrl = (method, prefix = 'v1/') => `${API_URL}/${prefix}${method}`;

export const setup = (state) =>
  new Promise((resolve) => {
    config.token = getAuthData(state);
    jwtInterceptor();
    resolve();
  });

const jwtInterceptor = () => {
  axios.interceptors.request.use((request) => {
    if (!request.url.startsWith(API_URL)) {
      return request;
    }
    request.headers.common['X-ClientId'] = CLIENT_ID;
    request.headers.common['X-Source'] = SOURCE;
    request.headers.common['Content-Type'] = 'application/json';
    const { token } = config;
    if (token.idToken) {
      request.headers.common.Authorization = `Bearer ${token.idToken}`;
    }
    return request;
  });
};

const generateCode = () => {
  const { origin } = window.location;
  const ts = moment().unix();
  const code = md5(`${CLIENT_ID}@${origin}@${ts}`);
  return { clientId: CLIENT_ID, code, ts };
};

const setAuthToken = (data) => {
  config.token = data;
};

// @api: /accessToken/web
export const fetchWebAccessToken = () => {
  const url = apiUrl('accessToken/web');
  const data = generateCode();
  return axios.post(url, data).then((res) => {
    if (res.data && res.data.idToken) {
      setAuthToken(res.data);
    }
    return res.data;
  });
};

// @api: /login/dp
export const login = (data) => {
  const url = apiUrl('login/dp');
  const submitData = { ...data, clientId: CLIENT_ID };
  return axios.post(url, submitData).then((res) => {
    if (res.data && res.data.idToken) {
      setAuthToken(res.data);
    }
    return res.data;
  });
};

// @api: /icmr/user
export const getUser = () => {
  const url = apiUrl('icmr/user');
  return axios.get(url).then((res) => res.data);
};

// @api: /icmr/testing/submit
export const submitTestingResult = (data) => {
  const url = apiUrl('icmr/testing/submit');
  return axios.post(url, data).then((res) => {
    if (res.data && res.data.idToken) {
      setAuthToken(res.data);
      return res.data;
    }
    return null;
  });
};

// @api: /gcs/accessToken
export const getGoogleApiTokens = () => {
  const url = apiUrl('gcs/accessToken');
  return axios.post(url).then((res) => res.data);
};

// @api: /upload/presigned-url
export const requestPreSignedUrl = (data) => {
  const url = apiUrl('gcs/presigned-url');
  return axios.post(url, data).then((res) => res.data);
};

// Analyze image via google cloud vision
export const analyzeImage = async (imageSrc) => {
  let tokens;
  try {
    tokens = await getGoogleApiTokens();
  } catch (err) {
    return { success: false, message: 'failed to acquire tokens' };
  }
  return analyzeImageWithGoogleVision(tokens, imageSrc).catch((err) => ({
    success: false,
    message: err.message,
  }));
};

// Upload image to google cloud stroage
export const uploadImage = async (
  folder,
  filePrefix,
  image /* base64 image */,
) => {
  const data = createBucketProps(folder, filePrefix);
  try {
    const { url, filepath } = await requestPreSignedUrl(data);
    const result = await uploadImageToGCS(url, image);
    if (result.status === 200) {
      return { filename: filepath, source: 'gcs', ts: moment().unix() };
    }
  } catch (error) {
    // TODO: how to handle?
  }
  return {};
};
