import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { goToTimer } from '../../../helpers/router';
import { processImage } from '../../../redux/actions/index';
import { fixedDiv } from '../../components/layout';
import Camera from './Camera';
import Preview from './Preview';

class TakePhoto extends PureComponent {
  state = { preview: false, imageSrc: null };

  camera = React.createRef();

  takePhoto = async (e) => {
    e.preventDefault();
    e.target.disabled = true;
    try {
      const imageSrc = this.camera.current.getScreenshot();
      this.setState({ imageSrc, preview: true });
    } catch (err) {
      // TODO: handle error.
    }
  };

  back = (e) => {
    e.preventDefault();
    goToTimer();
  };

  retake = async (e) => {
    e.preventDefault();
    this.setState({ preview: false, imageSrc: null });
  };

  proceed = async (e) => {
    e.preventDefault();
    const { imageSrc } = this.state;
    this.props.processImage({ imageSrc });
  };

  render() {
    const { preview, imageSrc } = this.state;
    return (
      <Container>
        <Content>
          {!preview && (
            <Camera
              ref={this.camera}
              onBack={this.back}
              onTakePhoto={this.takePhoto}
            />
          )}
          {preview && (
            <Preview
              image={imageSrc}
              onRetake={this.retake}
              onProceed={this.proceed}
            />
          )}
        </Content>
      </Container>
    );
  }
}

const mapDispatchToProps = {
  processImage,
};

export default connect(null, mapDispatchToProps)(TakePhoto);

//
// Styled Components
//

const Container = styled.div`
  ${fixedDiv};
  background-color: #970e53;
  height: 100%;
`;

const Content = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: 640px;
  width: 100%;
  height: 100%;
`;
