import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { isIndia, scrollToTop } from '../../../helpers';
import { submitUserProfile } from '../../../redux/actions/index';
import { getUserProfile } from '../../../redux/selectors';
import { updateUserProfile } from '../../../redux/slices/userSlice';
import { timeout } from '../../../utils/threading';
import Button from '../../components/forms/Button';
import ButtonContainer from '../../components/forms/ButtonContainer';
import FormGroup from '../../components/forms/FormGroup';
import {
  EmailInput,
  MobileInput,
  NameInput,
} from '../../components/forms/inputs';
import {
  CountryPicker,
  GenderPicker,
  MobileOwnerPicker,
} from '../../components/forms/selectors';
import AgePicker from '../../components/forms/selectors/AgePicker';
import AadharInput from './components/AadharInput';
import AddressInput from './components/Address/AddressInput';
import PassportInput from './components/PassportInput';
import ProfileHeader from './components/ProfileHeader';

class UserProfile extends PureComponent {
  // for tab to next fields
  formId = 'frmUserProfile';

  fields = ['name', 'gender', 'age', 'mobile'];

  inputTypes = { gender: 'select' };

  async componentDidMount() {
    await timeout(1);
    scrollToTop();
  }

  focusNextField = ({ name }) => {
    const { fields } = this;
    const tabIndex = fields.indexOf(name);
    if (tabIndex < 0 || tabIndex >= fields.length) {
      return;
    }
    // dismiss keyboard if it's the last field
    if (tabIndex === fields.length - 1) {
      this.getInputElement(name).blur();
      return;
    }
    const nextName = fields[tabIndex + 1];
    const inputType = this.inputTypes[nextName] || 'input';
    this.getInputElement(nextName, inputType).focus();
  };

  getInputElement = (name, inputType = 'input') =>
    document
      .getElementById(this.formId)
      .querySelector(`${inputType}[name="${name}"]`);

  render() {
    const { user } = this.props;
    const showAadhar = isIndia(user.nationality);
    return (
      <>
        <ProfileHeader />
        <FormGroup
          id={this.formId}
          onChange={this.props.updateUserProfile}
          onEnter={this.focusNextField}
        >
          <NameInput value={user.name} />
          <GenderPicker value={user.gender} />
          <AgePicker value={user.birthDate} />
          <MobileInput className="mobile-field" value={user.mobile} />
          <MobileOwnerPicker
            className="mobile-owner-field"
            value={user.mobileOwner}
          />
          <CountryPicker value={user.nationality} />
          <EmailInput value={user.email} />
        </FormGroup>

        <FormGroup
          id={`${this.formId}-1`}
          onChange={this.props.updateUserProfile}
          fadeIn
        >
          {showAadhar && <AadharInput value={user.aadharNumber} />}
          {!showAadhar && <PassportInput value={user.passportNumber} />}
          <AddressInput value={user} />
        </FormGroup>
        <ButtonContainer>
          <Button onClick={() => this.props.submitUserProfile()}>
            Continue
          </Button>
        </ButtonContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: getUserProfile(state),
});

const mapDispatchToProps = {
  updateUserProfile,
  submitUserProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
