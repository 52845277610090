import { navigate as rNavigate, redirectTo } from '@reach/router';
import { ROUTER } from '../config/router';

const getPath = (path) => `${ROUTER.ROOT_DIR}${path}`;

const getHome = () => getPath(ROUTER.LOGIN);

const navigate = (to, options = {}) => {
  let toPath = to;
  if (!/^\//.test(toPath)) {
    toPath = getPath(to);
  }
  rNavigate(toPath, options);
};

const goTo = (to, redirect = false, options = {}) => {
  if (redirect) {
    redirectTo(to);
  } else {
    navigate(to, options);
  }
};

const isRootDir = ({ pathname }) => pathname === ROUTER.ROOT_DIR;

const isPathStartsWith = ({ pathname }, needle) => {
  const path = getPath(needle);
  return pathname.startsWith(path);
};

export const isHomePage = (location) =>
  isRootDir(location) || isLoginPage(location);

export const isLoginPage = (location) =>
  isPathStartsWith(location, ROUTER.LOGIN);

export const isUserProfilePage = (location) =>
  isPathStartsWith(location, ROUTER.USER_PROFILE);

export const isUserExtraInfoPage = (location) =>
  isPathStartsWith(location, ROUTER.USER_PROFILE_EXTRA);

export const isSampleCollectionPage = (location) =>
  isPathStartsWith(location, ROUTER.SAMPLE_COLLECTION);

export const isHealthConditionPage = (location) =>
  isPathStartsWith(location, ROUTER.HEALTH_CONDITION);

export const isVaccinationPage = (location) =>
  isPathStartsWith(location, ROUTER.VACCINATION_INFO);

export const isTestingProcessPage = (location) =>
  isPathStartsWith(location, ROUTER.TESTING_PROCESS);

export const isTakePhonePage = (location) =>
  isPathStartsWith(location, ROUTER.TAKE_PHOTO);

export const isTestResultPage = (location) =>
  isPathStartsWith(location, ROUTER.TEST_RESULT);

export const isHealthReportPage = (location) =>
  isPathStartsWith(location, ROUTER.HEALTH_REPORT);

export const showStartOver = (location) => !isHomePage(location);

export const redirectToHome = () => {
  window.location = getHome();
};

export const goToHome = () => {
  const to = getHome();
  goTo(to, false, { replace: true });
};

export const goToUserProfilePage = (redirect = false) => {
  const to = ROUTER.USER_PROFILE;
  goTo(to, redirect);
};

export const goToUserExtraInfoPage = (redirect = false) => {
  const to = ROUTER.USER_PROFILE_EXTRA;
  goTo(to, redirect);
};

export const goToSampleCollectionPage = () => {
  const to = ROUTER.SAMPLE_COLLECTION;
  goTo(to, false);
};

export const goToHealthConditionPage = () => {
  const to = ROUTER.HEALTH_CONDITION;
  goTo(to, false);
};

export const goToVaccinationPage = () => {
  const to = ROUTER.VACCINATION_INFO;
  goTo(to, false);
};

export const goToTestProcessStep = (step = 0, redirect = false) => {
  const to = `${ROUTER.TESTING_PROCESS}/step-${step + 1}`;
  goTo(to, redirect);
};

export const goToTimer = (redirect = false) => {
  const to = `${ROUTER.TESTING_PROCESS}/timer`;
  goTo(to, redirect);
};

export const goToTakePhoto = (redirect = false) => {
  const to = `${ROUTER.TAKE_PHOTO}`;
  goTo(to, redirect);
};

export const goToTestResult = () => {
  const to = `${ROUTER.TEST_RESULT}`;
  goTo(to, false, { replace: true });
};

export const goToReportPage = () => {
  const to = `${ROUTER.HEALTH_REPORT}`;
  goTo(to, false, { replace: true });
};

// keys are the same as in config/router.js
const pageOrder = [
  'USER_PROFILE',
  'USER_PROFILE_EXTRA',
  'HEALTH_CONDITION',
  'VACCINATION_INFO',
  'SAMPLE_COLLECTION',
  'TESTING_PROCESS',
];

export const goToNext = (from) => {
  const ndx = pageOrder.indexOf(from);
  if (ndx < pageOrder.length - 1) {
    const page = pageOrder[ndx + 1];
    goTo(ROUTER[page], false);
  }
};

export const goToPrevious = (from) => {
  const ndx = pageOrder.indexOf(from);
  if (ndx > 0) {
    const page = pageOrder[ndx - 1];
    goTo(ROUTER[page], false);
  }
};
