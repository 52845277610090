import React from 'react';
import MultiSelection from '../../components/forms/MultiSelection';

const data = {
  title: 'Underlying Medical Condition',
  options: [
    { label: 'Chronic Lung Disease', value: 'Chronic Lung Disease' },
    { label: 'Chronic Kidney Disease', value: 'Chronic Kidney Disease' },
    { label: 'Diabetes', value: 'Diabetes' },
    { label: 'Heart Disease', value: 'Heart Disease' },
    { label: 'Cancer', value: 'Cancer' },
    { label: 'Hypertension', value: 'Hypertension' },
    { label: 'Obesity', value: 'Obesity' },
    { label: 'None of the above', value: 'none' },
  ],
};

export default function MedicalConditions({ value = [], ...restProps }) {
  return (
    <MultiSelection
      name="medicalConditions"
      data={data}
      selectedValues={value}
      {...restProps}
    />
  );
}
