import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { getMobileOwnerText } from '../../../../helpers/strings';
import withInputContainer from '../../hoc/withInputContainer';
import { inputCss } from '../../layout';
import { textStyles } from '../../text';

const options = ['patient', 'relative'];

class MobileOwnerPicker extends PureComponent {
  name = 'mobileOwner';

  onChange = (event) => {
    event.preventDefault();
    if (this.props.onChange) {
      const { name } = this;
      const { value } = event.target;
      this.props.onChange({ name, value });
    }
  };

  renderOptions() {
    return options.map((option, index) => {
      const key = `${index}`;
      const label = getMobileOwnerText(option);
      return (
        <option key={key} value={option}>
          {label}
        </option>
      );
    });
  }

  render() {
    const { name } = this;
    const { value } = this.props;
    const text = getMobileOwnerText(value);
    return (
      <Container>
        <Text>{text}</Text>
        <select name={name} value={value} onChange={this.onChange}>
          {this.renderOptions()}
        </select>
      </Container>
    );
  }
}

export default withInputContainer(MobileOwnerPicker, {
  label: 'Contact number belongs to',
});

//
// Styled Components
//

const Container = styled.div`
  ${inputCss};
  padding-right: 0;
  padding-left: 0;

  > select {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
  }
`;

const Text = styled.span`
  ${textStyles.input};
  ${inputCss};
`;
