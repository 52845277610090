export const ROUTER = {
  ROOT_DIR: process.env.REACT_APP_ROOT_DIR,
  LOGIN: 'login',
  USER_PROFILE: 'profile',
  USER_PROFILE_EXTRA: 'profile-additional-info',
  SAMPLE_COLLECTION: 'sample-collection',
  HEALTH_CONDITION: 'health-condition',
  VACCINATION_INFO: 'vaccination',
  TESTING_PROCESS: 'testing-process',
  TAKE_PHOTO: 'testing/take-photo',
  TEST_RESULT: 'testing/test-result',
  HEALTH_REPORT: 'testing/report',
};
