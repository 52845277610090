import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { started } from '../../../redux/slices/appSlice';
import { isIPhone } from '../../../utils/check';
import { timeout } from '../../../utils/threading';
import { Logo as LogoIcon } from '../../components/images/Logo';

export default function UnsupportedBrowser() {
  const dispatch = useDispatch();
  const [copied, setCopied] = useState(false);
  useEffect(async () => {
    await timeout(1000);
    dispatch(started());
  }, []);

  const iPhone = isIPhone();
  const url = window.location.href;
  const btnText = copied ? 'Link Copied' : 'Copy Link';
  const copyUrl = async () => {
    const copyText = document.getElementById('siteUrl');
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand('copy');
    setCopied(true);
    copyText.blur();
    await timeout(2000);
    setCopied(false);
  };

  return (
    <Container>
      <Logo />
      <Desc>Unsupported browser</Desc>
      {iPhone && (
        <LinkText href={url} target="_blank">
          Open in default browser
        </LinkText>
      )}
      {!iPhone && (
        <>
          <Message>Please copy the link and paste it in your browser</Message>
          <form>
            <Input id="siteUrl" value={url} />
            <Button type="button" onClick={copyUrl}>
              {btnText}
            </Button>
          </form>
        </>
      )}
      <UserAgentInfo>{window.navigator.userAgent}</UserAgentInfo>
    </Container>
  );
}

//
// Styled Components
//

const Container = styled.div`
  position: relative;
  text-align: center;
  padding: 50px 20px;
  max-width: 640px;
  margin: 0 auto;
`;

const Logo = styled(LogoIcon)`
  margin: 0 auto;
  width: 200px;
`;

const Desc = styled.p`
  font-size: 1.85rem;
  font-weight: bold;
  margin: 30px 0;
`;

const Input = styled.input.attrs(() => ({
  type: 'text',
  readyOnly: true,
}))`
  width: 100%;
  max-width: 320px;
  display: block;
  text-align: center;
  margin: 20px auto;
  border-radius: 8px;
  border-width: 1px;
  border-color: #ccc;
  border-style: solid;
  line-height: 2;
`;

const LinkText = styled.a`
  font-size: 1.25rem;
`;

const Message = styled.p`
  margin: 20px 0 30px;
  font-size: 1.25rem;
`;

const Button = styled.button.attrs(() => ({
  type: 'button',
  className: 'primary',
}))`
  width: 150px;
`;

const UserAgentInfo = styled.p`
  position: fixed;
  bottom: 0;
  left: 30px;
  right: 30px;
  padding: 30px 0;
`;
