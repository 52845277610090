import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    idToken: null,
    expires: 0,
  },
  reducers: {
    setToken: (state, { payload }) => {
      const { idToken, expires } = payload;
      state.idToken = idToken;
      state.expires = expires;
    },
  },
});

export const { setToken } = authSlice.actions;

export default authSlice.reducer;
