import validateJS from 'validate.js';
import userProfileConstraints from './constraints/userProfile';

const validate = (obj, constraints, options = {}) => {
  const settings = { fullMessages: false, ...options };
  return validateJS(obj, constraints, settings);
};

export const validateUserProfile = (user) =>
  validate(user, userProfileConstraints);
