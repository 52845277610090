import { takeLatest } from 'redux-saga/effects';
import { disableWindowScroll, enableWindowScroll } from '../../../helpers';
import { hideLoader, showLoader } from '../../slices/loaderSlice';

export function* showLoaderSaga() {
  yield takeLatest(showLoader.type, disableWindowScroll);
}

export function* hideLoaderSaga() {
  yield takeLatest(hideLoader.type, enableWindowScroll);
}
