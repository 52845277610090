import { createSlice } from '@reduxjs/toolkit';

export const vaccinationSlice = createSlice({
  name: 'vaccination',
  initialState: {
    vaccinated: '',
    type: '',
    doseDate1: '',
    doseDate2: '',
  },
  reducers: {
    updateVaccinationInfo: (state, { payload }) => {
      const { name: field, value } = payload;
      state[field] = value;
      if (field === 'vaccinated' && value === 'No') {
        state.type = '';
        state.doseDate1 = '';
        state.doseDate2 = '';
      }
    },
    setVaccinationInfo: (state, { payload }) => ({ ...state, ...payload }),
  },
});

export const { updateVaccinationInfo, setVaccinationInfo } =
  vaccinationSlice.actions;

export default vaccinationSlice.reducer;
