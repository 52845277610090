import styled from 'styled-components';
import { logo } from '../../../config/images';

export const Logo = styled.img.attrs(() => ({
  src: logo.src,
  alt: logo.title,
}))`
  width: 40%;
  max-width: 230px;
  margin: 20px 30px 10px;
  display: block;
`;
