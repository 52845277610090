import { createSlice } from '@reduxjs/toolkit';

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    loaded: false,
    resultImage: null,
  },
  reducers: {
    started: (state) => {
      state.loaded = true;
    },
    setResultImage: (state, { payload }) => {
      state.resultImage = payload;
    },
  },
});

export const { started, setResultImage } = appSlice.actions;

export default appSlice.reducer;
