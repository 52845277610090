import React from 'react';
import styled from 'styled-components';
import { PageContainer } from '../../components/layout/PageContainer';

export default function Footer({ onClickLink }) {
  const showDisclaimer = (e) => {
    e.preventDefault();
    onClickLink('disclaimer');
  };

  return (
    <Container className="main-footer">
      <AboutUsLink href="https://sensingself.ai">About Us</AboutUsLink>
      <DisclaimerLink onClick={showDisclaimer}>Disclaimer</DisclaimerLink>
    </Container>
  );
}

//
// Styled Components
//

const Container = styled(PageContainer)`
  padding: 10px 32px;
  text-align: center;
`;

const AboutUsLink = styled.a.attrs(() => ({
  className: 'link',
  target: '_blank',
}))`
  margin: 0 10px;
`;

const DisclaimerLink = styled.button.attrs(() => ({
  className: 'link',
  type: 'button',
}))`
  margin: 0 10px;
`;
