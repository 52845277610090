import React from 'react';
import { HeaderBox } from '../layout';

export default (WrappedComponent, className = '') =>
  (props) =>
    (
      <HeaderBox theme="white" className={className}>
        <WrappedComponent {...props} />
      </HeaderBox>
    );
