import React from 'react';
import { HeaderBox } from '../../../components/layout';
import { Description, Title } from '../../../components/text';

export default function ProfileHeader() {
  return (
    <HeaderBox>
      <Title>Your Profile Information</Title>
      <Description>
        Please provide us with your personal information as per Govt. Of India
        Guidelines:
      </Description>
    </HeaderBox>
  );
}
