import React from 'react';
import styled from 'styled-components';
import { inputCss } from '../../layout';
import { textStyles } from '../../text';

export default function UnitSelector(props) {
  const { label, renderOptions, ...rest } = props;
  return (
    <UnitContainer>
      <Text>{label}</Text>
      <select {...rest} tabIndex="-1">
        {renderOptions()}
      </select>
    </UnitContainer>
  );
}

const UnitContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  overflow: hidden;

  > select {
    position: absolute;
    top: 0;
    right: 0;
    width: 5;
    height: 100%;
    z-index: 1;
    opacity: 0;
  }
`;

const Text = styled.span`
  ${textStyles.input};
  ${inputCss};
  padding-left: 5px;
  display: block;
`;
