import React from 'react';
import styled from 'styled-components';
import { absoluteFill } from '../../components/layout';

import TestStripGuide from '../../../assets/images/test_strip_guide.svg';

export default function OverlayGuidance(props) {
  const { guide = true, renderHeader, renderFooter } = props;
  return (
    <Container>
      <Header>{renderHeader && renderHeader()}</Header>
      <Content>{guide && <TestStripGuidance />}</Content>
      <Footer>{renderFooter && renderFooter()}</Footer>
    </Container>
  );
}

const TestStripGuidance = () => (
  <GuidanceWrapper>
    <Guide src={TestStripGuide} />
  </GuidanceWrapper>
);

//
// Styled Components
//

const backgroundColor = '#970E53';

const Container = styled.div`
  ${absoluteFill}
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  > div {
    position: relative;
  }
`;

const Header = styled.div`
  min-height: 140px;
  background-color: ${backgroundColor};
`;

const Footer = styled.div`
  height: 80px;
  background-color: ${backgroundColor};
`;

const Content = styled.div`
  flex-grow: 1;
  border-style: solid;
  border-color: ${backgroundColor};
  border-left-width: min(100px, 15vw);
  border-right-width: min(100px, 15vw);
  border-top-width: 0;
  border-bottom-width: 0;
  box-sizing: border-box;
`;

const GuidanceWrapper = styled.div`
  position: absolute;
  width: 98%;
  height: 98%;
  top: 1%;
  left: 1%;
`;

const Guide = styled.img`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: block;
`;
