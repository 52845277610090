import { isEmpty } from 'lodash';
import React, { PureComponent } from 'react';
import { HeaderBox } from '.';
import { scrollToTop } from '../../../helpers';
import { timeout } from '../../../utils/threading';
import { showErrorPopup } from '../../modal/popup';
import { NextButton, PrevButton } from '../forms/Button';
import ButtonContainer from '../forms/ButtonContainer';
import FormGroup from '../forms/FormGroup';
import { Description, Title } from '../text';

export default class BasePage extends PureComponent {
  header = {
    title: 'Base Page Title',
    description: 'Base Page Description ',
  };

  formId: 'baseForm';

  async componentDidMount() {
    await timeout(1);
    scrollToTop();
  }

  onClickPrev = (e) => {
    e.preventDefault();
  };

  onClickNext = (e) => {
    e.preventDefault();
  };

  allEntered(values) {
    const allEntered = Object.values(values).every((value) => !isEmpty(value));
    if (!allEntered) {
      setTimeout(this.showError, 1);
    }
    return allEntered;
  }

  showError() {
    showErrorPopup('Please answer all the questions.');
  }

  renderHeader() {
    const { title, description } = this.header;
    return (
      <HeaderBox>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </HeaderBox>
    );
  }

  renderFooter() {
    return (
      <ButtonContainer>
        <PrevButton onClick={this.onClickPrev}>Back</PrevButton>
        <NextButton onClick={this.onClickNext}>Continue</NextButton>
      </ButtonContainer>
    );
  }

  renderOptions() {
    return null;
  }

  render() {
    const formProps = {
      id: this.formId,
      onChange: this.onChange,
      fadeIn: true,
    };

    return (
      <>
        {this.renderHeader()}
        <FormGroup {...formProps}>{this.renderOptions()}</FormGroup>
        {this.renderFooter()}
      </>
    );
  }
}
