import React from 'react';
import SingleSelection from '../../../components/forms/SingleSelection';

const data = {
  title: 'Have you downloaded Aarogya Setu App on your phone?',
  options: [
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' },
  ],
};

export default function AarogyaSetuApp({ value, ...restProps }) {
  return (
    <SingleSelection
      name="aarogyaSetuAppDownloaded"
      data={data}
      selectedValue={value}
      {...restProps}
    />
  );
}
