export default class EventEmitter {
  static events = {};

  static counter = 0;

  static addListener(eventName, listener) {
    if (typeof eventName === 'string' && typeof listener === 'function') {
      EventEmitter.counter += 1;
      const eventId = `e_${EventEmitter.counter}`;
      EventEmitter.events[eventId] = { eventName, listener };
      return eventId;
    }
    return null;
  }

  static removeListener(eventId) {
    if (eventId) {
      delete EventEmitter.events[eventId];
    }
  }

  static emit(name, data) {
    Object.values(EventEmitter.events)
      .filter(({ eventName }) => eventName === name)
      .forEach(({ listener }) => {
        listener(data);
      });
  }

  static emitAsync(name, data) {
    const results = Object.values(EventEmitter.events)
      .filter(({ eventName }) => eventName === name)
      .map(({ listener }) => Promise.resolve(listener(data)));
    return Promise.all(results);
  }
}

export const subscribe = (eventName, callback) =>
  EventEmitter.addListener(eventName, callback);

export const unsubscribe = (eventIds) => {
  if (!eventIds) {
    return;
  }
  if (!Array.isArray(eventIds)) {
    EventEmitter.removeListener(eventIds);
    return;
  }
  eventIds.forEach((eventId) => {
    EventEmitter.removeListener(eventId);
  });
};
