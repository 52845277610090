import { isEmpty } from 'lodash';
import moment from 'moment';
import { nanoid } from 'nanoid';
import React from 'react';
import FadeIn from 'react-fade-in';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import PrintSVG from '../../../assets/images/print_white_circle.svg';
import StartOverSVG from '../../../assets/images/start_over_white_circle.svg';
import appData from '../../../config/data';
import { scrollToTop } from '../../../helpers';
import { getResultText, getSampleType } from '../../../helpers/strings';
import { mapToUserInfo } from '../../../mappers/user';
import { completeTest } from '../../../redux/actions';
import {
  getSampleData,
  getTestingResult,
  getUserProfile,
  getVaccinationInfo,
  hasSymptoms,
} from '../../../redux/selectors';
import { IconButton } from '../../components/forms/Button';
import { Logo as SSLogo } from '../../components/images/Logo';
import {
  fonts,
  SmallerText,
  Text as SSText,
  Title as SSTitle,
} from '../../components/text';

const resultDesc = appData.loadTestingResult();

const getResultDesc = (result, symptoms = false) => {
  let key = 'invalid';
  if (result === '+') {
    key = 'positive';
  } else if (result === '-' && symptoms) {
    key = 'negativeWithSymptoms';
  } else if (result === '-') {
    key = 'negative';
  }
  return resultDesc[key].description;
};

const getResultColor = (value) => {
  if (value === '+') {
    return '#F00008';
  }
  if (value === '-') {
    return '#03C613';
  }
  return 'black';
};

export default function HealthReport() {
  const userProfile = useSelector(getUserProfile);
  const sample = useSelector(getSampleData);
  const symptoms = useSelector(hasSymptoms);
  const testingResult = useSelector(getTestingResult);
  const vaccination = useSelector(getVaccinationInfo);
  const dispatch = useDispatch();
  const user = mapToUserInfo(userProfile);
  const sampleType = getSampleType(sample.type);
  const { testingId } = testingResult;
  const { result } = testingResult.result;
  const resultText = getResultText(result);
  const dateTime = moment(testingResult.testingTime);
  const time = dateTime.format('hh:mm A');
  const date = dateTime.format('MMMM Do, YYYY');

  const onClickPrint = (e) => {
    e.preventDefault();
    window.print();
  };

  const onClickStartOver = (e) => {
    e.preventDefault();
    dispatch(completeTest());
  };

  const onRenderComplete = () => {
    scrollToTop();
  };

  const renderDescription = () => {
    const descriptions = getResultDesc(result, symptoms);
    return descriptions.map((desc) => {
      const key = nanoid();
      const lines = desc
        .split('\n')
        .map((d) => <span key={nanoid()}>{d}</span>);
      return <Desc key={key}>{lines}</Desc>;
    });
  };

  const renderTestResult = () => (
    <>
      <TestID>{`Test ID: ${testingId}`}</TestID>
      <BoxContainer>
        <UserSection>
          <LineItem label="Name">{user.name}</LineItem>
          <LineItem label="Gender">{user.gender}</LineItem>
          <LineItem label="Nationality">{user.nationality}</LineItem>
          <LineItem label={user.id.label}>{user.id.value}</LineItem>
          <LineItem label="Sample Type">{sampleType}</LineItem>
        </UserSection>
        <TimeSection>
          <LineItem label="Time">{time}</LineItem>
          <LineItem label="Date">{date}</LineItem>
        </TimeSection>
        <ResultSection result={result}>
          <LineItem label="Your Result">{resultText}</LineItem>
        </ResultSection>
        {renderDescription()}
      </BoxContainer>
    </>
  );

  const renderVaccineStatus = () => {
    const { vaccinated, type, doseDate1, doseDate2 } = vaccination;
    const date1 = isEmpty(doseDate1)
      ? ''
      : moment(doseDate1).format('DD/MM/YYYY');
    const date2 = isEmpty(doseDate2)
      ? ''
      : moment(doseDate2).format('DD/MM/YYYY');
    return (
      <>
        <SectionTitle className="title ">Vaccination Status</SectionTitle>
        <BoxContainer>
          <UserSection>
            <LineItem label="Vaccinated">{vaccinated}</LineItem>
            <LineItem label="Vaccine">{type}</LineItem>
            <LineItem label="Date dose 1">{date1}</LineItem>
            <LineItem label="Date dose 2">{date2}</LineItem>
          </UserSection>
        </BoxContainer>
      </>
    );
  };

  return (
    <>
      <Header>
        <Logo />
      </Header>
      <Container>
        <MainTitle className="title">
          Antigen Test
          <br />
          Personal Passport
        </MainTitle>
        <FadeIn onComplete={onRenderComplete}>
          {renderTestResult()}
          {renderVaccineStatus()}
          <Disclaimer>
            * This is not legal document and cannot be used to present as legal
            proof of your health unless authorized by your government or
            employer for verification purpose.
          </Disclaimer>
          <ButtonContainer>
            <PrintButton onClick={onClickPrint}>Print</PrintButton>
            <StartOverButton onClick={onClickStartOver}>
              Start Over
            </StartOverButton>
          </ButtonContainer>
        </FadeIn>
      </Container>
    </>
  );
}

const LineItem = ({ label, children }) => (
  <Content>
    <Label>{label}:</Label>
    <Text>{children}</Text>
  </Content>
);

//
// Styled Components
//
const Header = styled.div`
  padding: 16px 22px;

  @media print {
    background-color: white;
    padding-bottom: 0;
  }
`;

const TestID = styled.div`
  color: white;
  font-size: 1.5rem;
  padding: 4px 5px 24px;

  @media (max-width: 450px) {
    font-size: 1.3rem;
    padding: 0 5px 18px;
  }

  @media print {
    color: black;
    padding-left: 0;
    font-size: 1.65rem;
  }
`;

const Logo = styled(SSLogo)`
  margin: 0;

  @media print {
    width: 150px !important;
  }
`;

const Container = styled.div`
  position: relative;
  padding: 144px 18px 20px;
  border-radius: 20px;
  background-color: #970e53;
  margin-bottom: 60px;
  overflow: hidden;
  @media print {
    background-color: white;
    border-radius: 0;
    padding-top: 74px;
  }
`;

const BoxContainer = styled.div`
  position: relative;
  ${fonts.primary};
  font-size: 1.65rem;
  border-radius: 20px;
  background: white;
  padding: 24px;
  box-sizing: border-box;
  overflow: hidden;

  @media (max-width: 450px) {
    font-size: 1.3rem;
    padding: 24px 22px;
  }

  @media print {
    padding: 0;
    border-radius: 0px;
  }
}
`;

const Title = styled(SSTitle)`
  color: white;
  font-size: 2.4rem;
  padding: 0px 0px 14px 5px;

  @media (max-width: 450px) {
    font-size: 2rem;
  }

  @media print {
    color: black !important;
    padding: 0px 0 px 30px 0px;
  }
`;

const MainTitle = styled(Title)`
  background-color: black;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: block;
  box-sizing: border-box;
  padding: 25px;

  @media print {
    background: transparent;
    padding-left: 20px;
    padding-top: 14px;

    br {
      display: none;
    }
  }
`;

const SectionTitle = styled(Title)`
  margin: 30px 0 10px;

  @media print {
    background: white;
    padding-left: 0px;
  }
`;

const Content = styled.div`
  display: block;
  margin-bottom: 15px;
  line-height: 1.15;

  @media (max-width: 450px) {
    margin-bottom: 10px;
  }
  @media print {
    line-height: 1;
    margin-bottom: 10px;
  }
`;

const Label = styled.span`
  display: table-cell;
  width: 175px;

  @media (max-width: 450px) {
    width: 110px;
  }
`;

const Text = styled.span`
  font-weight: bold;
  display: table-cell;
`;

const Desc = styled(SSText)`
  margin: 20px 0;
  font-size: 1.2rem;

  span {
    display: block;
  }

  @media print {
    margin: 10px 0;
  }
`;

const UserSection = styled.div`
  ${Label} {
    font-weight: bold;
  }
  ${Text} {
    font-weight: normal;
  }
`;

const TimeSection = styled.div`
  margin: 36px 0;

  @media (max-width: 450px) {
    margin: 28px 0;
  }
`;

const ResultSection = styled.div`
  font-size: 1.85rem;
  margin: 40px 0;

  ${Text} {
    color: ${({ result }) => getResultColor(result)};
  }

  @media (max-width: 450px) {
    font-size: 1.57rem;
  }
`;

const Disclaimer = styled(SmallerText)`
  color: white;
  font-size: 1.2rem;
  font-style: italic;
  margin: 20px 0 40px;

  @media (max-width: 450px) {
    font-size: 0.9rem;
  }

  @media print {
    color: black;
  }
`;

const ButtonContainer = styled.div`
  position: relative;
  text-align: center;
  margin: 50px 0 20px;

  @media print {
    display: none;
  }
`;

const PrintButton = styled(IconButton)`
  background-image: ${`url(${PrintSVG})`};
`;

const StartOverButton = styled(IconButton)`
  background-image: ${`url(${StartOverSVG})`};
`;
