import disclaimFilePath from '../assets/data/disclaimer.md';

const loadDisclaimer = (callback) => {
  fetch(disclaimFilePath)
    .then((response) => response.text())
    .then(callback);
};

const loadTestingProcess = (type) => {
  const t = type.toLowerCase();
  if (t === 'nasal swab') {
    return require('../assets/data/testingProcess_nasalSwab.json');
  }
  return require('../assets/data/testingProcess_sputum.json');
};

const loadTestingResult = () => require('../assets/data/testingResult.json');

export default {
  loadDisclaimer,
  loadTestingProcess,
  loadTestingResult,
};
