import React from 'react';
import FadeInView from '../layout/FadeInView';

export default (WrappedComponent, passProps = {}) => {
  const { dataField = 'data' } = passProps || {};

  class withFadeInView extends FadeInView {
    state = { check: null, fadeIn: false, dataField };

    renderComponent(props) {
      return <WrappedComponent {...props} />;
    }
  }

  return withFadeInView;
};
