import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import { fade } from '.';
import { timeout } from '../../../utils/threading';

// @abstract
export class FadeView extends PureComponent {
  constructor(props) {
    super(props);
    const { active } = this.props;
    const className = active ? 'active' : '';
    this.state = { className };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { active } = nextProps;
    if (active && prevState.className === 'active') {
      return null;
    }
    if (!active && prevState.className === '') {
      return null;
    }
    const className = active ? 'fadeIn' : 'fadeOut';
    return { className };
  }

  componentDidMount() {
    if (this.containerRef) {
      this.containerRef.addEventListener(
        'transitionend',
        this.onTransitionedEnd,
      );
    }
  }

  componentWillUnmount() {
    if (this.containerRef) {
      this.containerRef.removeEventListener(
        'transitionend',
        this.onTransitionedEnd,
      );
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const { className } = this.state;
    if (prevState.className !== className && className === 'fadeIn') {
      await timeout(1);
      this.setState({ className: 'active' });
    }
  }

  onTransitionedEnd = () => {
    if (!this.props.active) {
      this.setState({ className: '' });
    }
  };

  setContainerRef = (ref) => {
    this.containerRef = ref;
  };

  // @abstract
  renderComponent(activate, className /* , restProps */) {
    return (
      <Container
        ref={this.setContainerRef}
        className={className}
        duration={this.props.duration}
      >
        {this.props.children}
      </Container>
    );
  }

  render() {
    const { className } = this.state;
    const { active, ...restProps } = this.props;
    const activate = className === 'active';
    return this.renderComponent(activate, className, restProps);
  }
}

FadeView.propTypes = {
  active: PropTypes.bool,
  duration: PropTypes.number,
};

FadeView.defaultProps = {
  active: false,
  duration: 1,
};

const Container = styled.div`
  ${fade};
`;
