import React from 'react';
import FadeInView from '../../components/layout/FadeInView';
import TimerContent from './TimerContent';

export default class Timer extends FadeInView {
  renderComponent({ renderItem, renderFooter, ...props }) {
    const components = [<TimerContent {...props} key="timer" />];
    if (renderFooter) {
      components.push(renderFooter());
    }
    return components;
  }
}
