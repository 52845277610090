import React, { PureComponent } from 'react';
import DistrictSelector from './DistrictSelector';
import StateSelector from './StateSelector';

export default class LGDirectory extends PureComponent {
  render() {
    const { value: user, ...rest } = this.props;
    const showDistrictList = `${user.state}` !== '-1';
    return (
      <>
        <StateSelector value={user.state} {...rest} />
        {showDistrictList && (
          <DistrictSelector
            value={user.district}
            stateCode={user.state}
            {...rest}
          />
        )}
      </>
    );
  }
}
