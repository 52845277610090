import { isEmpty } from 'lodash';
import moment from 'moment';
import { call, delay, put, select } from 'redux-saga/effects';
import * as Api from '../../../api';
import {
  goToHome,
  goToReportPage,
  goToTestResult,
} from '../../../helpers/router';
import { showErrorPopup } from '../../../views/modal/popup';
import { startOver } from '../../actions';
import { getSubmitData, getTestingId } from '../../selectors';
import { setResultImage } from '../../slices/appSlice';
import { setToken } from '../../slices/authSlice';
import { hideLoader, showLoader } from '../../slices/loaderSlice';
import {
  imageProcessed,
  imageProcessFailed,
  testResultSubmitted,
  updateTestResult,
} from '../../slices/testingSlice';

export function* handleProcessImage({ payload }) {
  const { imageSrc } = payload;
  if (!imageSrc) {
    // TODO: show error message?
    return;
  }

  yield put(showProcessingImageMessage());

  // upload image to server for the analysis
  const testingId = yield select(getTestingId);
  const result = yield analyzeImage(imageSrc, testingId);
  const success = result && result.success;
  if (success) {
    const data = {
      result: result.result,
      prediction: result.prediction,
      resultImage: { ...result.file, ts: moment().unix() },
    };
    yield put(updateTestResult(data));
    const resultImage = yield cropAndResizeImage(
      imageSrc,
      result.prediction.boxes.strip,
    );
    yield put(setResultImage(resultImage));
    yield put(imageProcessed());
    yield put(showImageProcessedMessage());
  } else {
    yield put(imageProcessFailed());
    yield put(showImageProcessFailedMessage());
  }

  yield delay(2000);
  yield put(hideLoader());
  yield delay(500);

  if (success) {
    yield call(goToTestResult);
  }
}

function* analyzeImage(imageSrc, testingId) {
  const { success, ...predictResult } = yield call(Api.analyzeImage, imageSrc);
  if (!success) {
    return { success: false };
  }
  // upload to gcs
  const folder = 'testResults';
  const uploadResult = yield call(Api.uploadImage, folder, testingId, imageSrc);
  if (isEmpty(uploadResult.filename)) {
    return { success: false };
  }
  return {
    success: true,
    ...predictResult,
    file: uploadResult,
  };
}

function cropAndResizeImage(imageSrc, boundingBox) {
  return new Promise((resolve) => {
    if (!boundingBox) {
      resolve('');
    }

    const canvas = document.createElement('canvas');
    canvas.width = 110;
    canvas.height = 350; // height/width ratio is 3.2

    // crop and resize
    const [pos1, pos2] = boundingBox;
    const image = new Image();
    image.onload = () => {
      const ctx = canvas.getContext('2d');
      const x = image.width * pos1.x;
      const y = image.height * pos1.y;
      const w = image.width * pos2.x - x;
      const h = image.height * pos2.y - y;
      ctx.drawImage(image, x, y, w, h, 0, 0, canvas.width, canvas.height);
      canvas.toBlob((blob) => {
        resolve(URL.createObjectURL(blob));
      }, 'image/jpeg');
    };

    image.onerror = () => {
      resolve(null);
    };
    image.src = imageSrc;
  });
}

function showProcessingImageMessage() {
  return showLoader({
    message:
      'Please wait for the Test Result to be processed. \nThis may take several minutes depending on your internet speed. ',
    progressBar: true,
  });
}

export function* handleSubmitTestingResult() {
  yield put(showLoadMessage());
  try {
    // submit the final result to the server.
    const submitData = yield select(getSubmitData);
    const response = yield call(Api.submitTestingResult, submitData);
    if (response) {
      yield put(setToken(response));
    }
    yield put(testResultSubmitted());
    yield delay(1000);
    yield put(hideLoader());
    yield delay(500);
    yield call(goToReportPage);
  } catch (error) {
    showErrorPopup(
      'There was an error submitting your data. Please try again later.',
    );
  }
}

export function* handleCompleteTest() {
  goToHome();
  yield put(startOver());
}

function showImageProcessedMessage() {
  return showLoader({
    message: 'Image processing has been completed.',
    progressBar: false,
  });
}

function showImageProcessFailedMessage() {
  return showLoader({
    message: 'There was an error processing your image. Please try again.',
    progressBar: false,
  });
}

function showLoadMessage() {
  return showLoader({
    message: 'Creating health report.',
    progressBar: true,
  });
}
