import { alertFooter, confirm, showAlertConfirm, toDomElement, alert } from '.';
import Emergency from '../components/popup/Emergency';

export function showCallEmergencyPopup(onClose) {
  const content = toDomElement(Emergency, 'alert emergency');
  const footer = alertFooter;
  const closeBefore = (action, close) => {
    if (typeof onClose === 'function') {
      onClose();
    }
    close();
  };
  showAlertConfirm({ content, footer, closeBefore });
}

export function showStartOverPopup(onOK) {
  const message = 'Are you sure you want to start over?';
  confirm(message, onOK);
}

export function showErrorPopup(message) {
  return new Promise((resolve) => {
    alert(message, resolve);
  });
}
