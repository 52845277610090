import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import appData from '../../../config/data';
import withFadeOverlay from '../hoc/withFadeOverlay';
import PopupContainer from '../layout/PopupContainer';

const Disclaimer = ({ activate, onClose }) => {
  const [markdown, setMarkdown] = useState(null);
  useEffect(() => {
    appData.loadDisclaimer(setMarkdown);
  }, []);

  return (
    <PopupContainer className="markdown" onClose={onClose} show={activate}>
      <ReactMarkdown>{markdown}</ReactMarkdown>
    </PopupContainer>
  );
};

export default withFadeOverlay(Disclaimer, {
  duration: 0.3,
});
