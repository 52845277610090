import { call, delay, put, select } from 'redux-saga/effects';
import * as Api from '../../../api';
import { enableWindowScroll } from '../../../helpers';
import { registerOnChangeLocationListener } from '../../../helpers/location';
import {
  goToHome,
  goToTestProcessStep,
  goToUserProfilePage,
  isHealthReportPage,
  isHomePage,
  isTestResultPage,
} from '../../../helpers/router';
import { showErrorPopup } from '../../../views/modal/popup';
import { appSetup } from '../../actions';
import {
  getTestingId,
  hasCompleteUserProfile,
  hasProcessedImage,
  hasSubmittedResult,
} from '../../selectors';
import { started } from '../../slices/appSlice';
import { setToken } from '../../slices/authSlice';
import { hideLoader, showLoader } from '../../slices/loaderSlice';
import { createTestingId } from '../../slices/testingSlice';
import { setUserExtraInfo } from '../../slices/userExtraInfoSlice';
import { setUserInfo } from '../../slices/userSlice';
import { setVaccinationInfo } from '../../slices/vaccinationSlice';

export function* handleAppSetup({ payload } = {}) {
  yield call(fetchWebAccessToken);
  yield call(checkLocation, payload);
  yield call(checkTestingId);
  yield call(enableWindowScroll);
  yield registerOnChangeLocationListener;
  yield delay(1000);
  yield put(started());
}

function* fetchWebAccessToken() {
  try {
    const response = yield call(Api.fetchWebAccessToken);
    yield put(setToken(response));
  } catch (error) {
    // ignore error for now
  }
}

export function* handleLogin({ payload }) {
  try {
    yield put(
      showLoader({
        message: 'authenticating...',
        progressBar: true,
      }),
    );
    yield delay(500);
    const response = yield call(Api.login, payload);
    yield put(setToken(response));
    const userData = yield call(Api.getUser);
    yield put(setUserInfo(userData.user));
    yield put(setUserExtraInfo(userData.userExtraInfo));
    yield put(setVaccinationInfo(userData.vaccination));
    yield delay(500);
    yield put(hideLoader());
    yield delay(500);
    yield call(goToUserProfilePage);
  } catch (error) {
    yield put(hideLoader());
    yield delay(500);
    showErrorPopup('Error! Please check your login information again.');

    // ignore error for now
  }
}

function* checkLocation({ location } = {}) {
  if (!location || isHomePage(location)) {
    return;
  }
  const state = yield select();
  if (!hasCompleteUserProfile(state)) {
    goToHome();
    return;
  }
  if (isHealthReportPage(location) && !hasSubmittedResult(state)) {
    goToHome();
    return;
  }
  if (isTestResultPage(location) && !hasProcessedImage(state)) {
    goToTestProcessStep();
  }
}

function* checkTestingId() {
  const testingId = yield select(getTestingId);
  if (testingId.length < 1) {
    yield put(createTestingId());
  }
}

export function* handleStartOver() {
  yield put(appSetup());
  yield call(goToHome);
}
