import MobileDetect from 'mobile-detect';

const md = new MobileDetect(window.navigator.userAgent);

export const isMobileDevice = () => md.mobile();

export const isIPhone = () => md.is('iPhone');

export const isEmbeddedBrowser = () =>
  /build\//i.test(window.navigator.userAgent);

export const isOpenedByApp = () =>
  (md.is('iPhone') && (md.userAgent() || '').length < 1) || isEmbeddedBrowser();
