import React from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import moment from 'moment';
import {
  goToHealthConditionPage,
  goToNext,
  goToPrevious,
} from '../../../helpers/router';
import BasePage from '../../components/layout/BasePage';
import { getVaccinationInfo } from '../../../redux/selectors/index';
import { updateVaccinationInfo } from '../../../redux/slices/vaccinationSlice';
import Vaccinated from './Vaccinated';
import VaccineType from './VaccineType';
import VaccineDoseDate from './VaccineDoseDate';
import { showErrorPopup } from '../../modal/popup';

class VaccinationInfo extends BasePage {
  header = {
    title: 'Vaccination Information',
    description: 'Please provide a response regarding your vaccination status.',
  };

  formId = 'vaccinationInfoForm';

  onChange = this.props.updateVaccinationInfo;

  onClickPrev = (e) => {
    e.preventDefault();
    goToPrevious('VACCINATION_INFO');
  };

  onClickNext = (e) => {
    e.preventDefault();
    if (this.allEntered(this.props.vaccination)) {
      goToNext('VACCINATION_INFO');
    }
  };

  allEntered(values) {
    if (isEmpty(values.vaccinated)) {
      setTimeout(this.showError, 1);
      return false;
    }
    if (values.vaccinated === 'No') {
      return true;
    }
    const { type, doseDate1, doseDate2 } = values;
    if (isEmpty(type) || isEmpty(doseDate1)) {
      setTimeout(this.showError, 1);
      return false;
    }
    if (!isEmpty(doseDate2) && moment(doseDate2).isBefore(doseDate1)) {
      setTimeout(() => {
        const message = 'Please check your vaccine dose date again.';
        showErrorPopup(message);
      }, 1);
      return false;
    }
    return true;
  }

  renderOptions() {
    const { vaccinated, type, doseDate1, doseDate2 } = this.props.vaccination;
    const components = [<Vaccinated key="vaccinated" value={vaccinated} />];
    if (vaccinated !== 'Yes') {
      return components;
    }
    components.push(<VaccineType key="vaccineType" value={type} />);
    if (type !== '') {
      components.push(
        <VaccineDoseDate
          index="1"
          key="vaccineDose1"
          name="doseDate1"
          label="Vaccine Dose 1"
          value={doseDate1}
        />,
      );
    }
    if (doseDate1 !== '') {
      components.push(
        <VaccineDoseDate
          index="2"
          key="vaccineDose2"
          name="doseDate2"
          label="Vaccine Dose 2"
          value={doseDate2}
          minDate={doseDate1}
        />,
      );
    }

    return components;
  }
}

const mapStateToProps = (state) => ({
  vaccination: getVaccinationInfo(state),
});

const mapDispatchToProps = {
  updateVaccinationInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(VaccinationInfo);
