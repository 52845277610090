import React from 'react';
import SingleSelection from '../../../components/forms/SingleSelection';

const data = {
  title: 'Patient Category',
  options: [
    { label: 'All symptomatic (ILI symptoms) cases.', value: 'NCat1' },
    { label: 'All asymptomatic high-risk individuals.', value: 'NCat3' },
    {
      label:
        'All symptomatic (ILI symptoms) individuals with history of international travel in the last 14 days.',
      value: 'NCat4',
    },
    {
      label: 'All individuals who wish to get themselves tested.',
      value: 'NCat17',
    },
  ],
};

export default function PatientCategoryPatient({ value, ...restProps }) {
  return (
    <SingleSelection
      name="patientCategory"
      data={data}
      selectedValue={value}
      {...restProps}
    />
  );
}
