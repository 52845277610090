import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { isAuthTokenExpired } from '../../../helpers/auth';
import { showErrorPopup } from '../../../views/modal/popup';
import { appSetup, login, startOver } from '../../actions';
import { getAuthData, isAppStarted } from '../../selectors';
import { handleAppSetup, handleLogin, handleStartOver } from './handlers';

export function* appSetupSaga() {
  yield takeLatest(appSetup.type, handleAppSetup);
}

export function* startOverSaga() {
  yield takeLatest(startOver.type, handleStartOver);
}

export function* loginSaga() {
  yield takeLatest(login.type, handleLogin);
}

// check auth token every minute
export function* authTokenWatcher() {
  while (true) {
    yield delay(60000);
    const appStarted = yield select(isAppStarted);
    const authData = yield select(getAuthData);
    const tokenExpired = isAuthTokenExpired(authData);
    if (appStarted && tokenExpired) {
      yield put(startOver());
      yield delay(300);
      yield call(showErrorPopup, 'Your session has expired.');
    }
  }
}
