import React, { PureComponent } from 'react';
import { HeaderBox } from '../layout';
import { Title } from '../text';
import Option from './Option';

export default class SingleSelection extends PureComponent {
  onChange = (event) => {
    const { name } = this.props;
    const { value } = event.target;
    this.props.onChange({ name, value });
  };

  renderOptions() {
    const { data, name, selectedValue, onChange, onEnter, ...restProps } =
      this.props;

    return data.options.map((item, index) => {
      const key = `opt-${index}`;
      const { label, value } = item;
      const checked = selectedValue === value;
      return (
        <Option
          key={key}
          name={name}
          box={false}
          value={value}
          className="group-option"
          type="radio"
          checked={checked}
          onChange={this.onChange}
          {...restProps}
        >
          <Title>{label}</Title>
        </Option>
      );
    });
  }

  render() {
    const { title } = this.props.data;
    return (
      <HeaderBox className="group-selection" theme="white">
        <Title>{title}</Title>
        {this.renderOptions()}
      </HeaderBox>
    );
  }
}
