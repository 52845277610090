import { isEmpty } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { goToNext, goToPrevious } from '../../../helpers/router';
import { getHealthCondition } from '../../../redux/selectors';
import { updateHealthCondition } from '../../../redux/slices/healthSlice';
import BasePage from '../../components/layout/BasePage';
import MedicalConditions from './MedicalCondtions';
import Status from './Status';
import SymptomDate from './SymptomDate';
import Symptoms from './Symptoms';

class HealthCondition extends BasePage {
  header = {
    title: 'Your Current Health Condition',
    description:
      'Please select one or more of the conditions you may be suffering from currently.',
  };

  formId = 'healthConditionForm';

  onChange = this.props.updateHealthCondition;

  onClickPrev = (e) => {
    e.preventDefault();
    goToPrevious('HEALTH_CONDITION');
  };

  onClickNext = (e) => {
    e.preventDefault();
    if (this.allEntered(this.props.health)) {
      goToNext('HEALTH_CONDITION');
    }
  };

  hasSymptom = ({ status }) => status === 'Symptomatic';

  allEntered(values) {
    let allEntered = false;
    if (this.hasSymptom(values)) {
      return super.allEntered(values);
    }
    allEntered = !isEmpty(values.status) && !isEmpty(values.medicalConditions);
    if (!allEntered) {
      setTimeout(this.showError, 1);
    }
    return allEntered;
  }

  renderOptions() {
    const { status, symptoms, symptomsDate, medicalConditions } =
      this.props.health;
    const components = [<Status key="status" value={status} />];

    if (this.hasSymptom(this.props.health)) {
      components.push(
        <Symptoms key="symptoms" value={symptoms} />,
        <SymptomDate
          key="symptomDate"
          value={symptomsDate}
          className="input-box symptom-date-field"
        />,
      );
    }
    if (status !== '') {
      components.push(
        <MedicalConditions key="medical" value={medicalConditions} />,
      );
    }
    return components;
  }
}

const mapStateToProps = (state) => ({
  health: getHealthCondition(state),
});

const mapDispatchToProps = {
  updateHealthCondition,
};

export default connect(mapStateToProps, mapDispatchToProps)(HealthCondition);
