import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

export const sampleSlice = createSlice({
  name: 'sample',
  initialState: {
    date: moment().format(),
    type: '',
    collectedFrom: '',
  },
  reducers: {
    updateSampleData: (state, { payload }) => {
      const { name: field, value } = payload;
      state[field] = value;
    },
  },
});

export const { updateSampleData } = sampleSlice.actions;

export default sampleSlice.reducer;
