export const timeout = (ms = 30000) =>
  new Promise((res) => setTimeout(res, ms));

export const resolver = () => {
  let thisResolve;
  let thisReject;
  const promise = new Promise((resolve, reject) => {
    thisResolve = resolve;
    thisReject = reject;
  });
  return {
    promise,
    token: {
      done: thisResolve,
      cancel: thisReject,
    },
  };
};
