import { createAction } from '@reduxjs/toolkit';

//
// app
//
export const appSetup = createAction('app/setup');
export const startOver = createAction('app/startOver');
export const checkLocation = createAction('app/checkLocation');

//
// auth
//
export const login = createAction('auth/login');

//
// event
//
export const emitEvent = createAction('event/emit');

//
// user profile
//
export const submitUserProfile = createAction('user/submitUserProfile');

//
// user extra info
//
export const submitUserExtraInfo = createAction('user/submitUserExtraInfo');

//
// testing
//
export const startTesting = createAction('testing/start');
export const processImage = createAction('testing/processImage');
export const completeTest = createAction('testing/completeTest');
export const submitTestResult = createAction('testing/submitTestResult');
