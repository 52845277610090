import React from 'react';
import { CountryPicker } from '../../../../components/forms/selectors/CountryPicker';
import withInputContainer from '../../../../components/hoc/withInputContainer';

// load country data
const states = require('../../../../../assets/data/LG_Directory.json');

const getStateName = (code) => {
  const filtered = states.filter((s) => `${s.code}` === `${code}`);
  return filtered.length > 0 ? filtered[0].name : '';
};

class StateSelector extends CountryPicker {
  name = 'state';

  getText = () => {
    const { value } = this.props;
    return getStateName(value);
  };

  getFirstOptionValue = () => states[0].code;

  hasValue = () => `${this.props.value}` !== '-1';

  renderOptions() {
    return states.map((state, index) => {
      const key = `state-${index}`;
      const { name, code } = state;
      return (
        <option key={key} value={code}>
          {name}
        </option>
      );
    });
  }
}

export default withInputContainer(StateSelector, {
  label: 'State',
});
