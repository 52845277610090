import Lottie from 'lottie-react';
import React from 'react';
import styled from 'styled-components';
import progress from '../../../assets/lottie/progress.json';
import withFadeOverlay from '../hoc/withFadeOverlay';
import { Text } from '../text';

const MessageBox = ({ message, progressBar }) => (
  <Container>
    <LabelContainer>
      <Text>{message}</Text>
      {progressBar && <ProgressBar />}
    </LabelContainer>
  </Container>
);

const ProgressBar = () => (
  <ProgressContainer>
    <Lottie animationData={progress} autoPlay loop />
  </ProgressContainer>
);

//
// Styled Components
//

const Container = styled.div`
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LabelContainer = styled.div`
  background-color: white;
  border-radius: 20px;
  padding: 26px 36px;
  box-sizing: border-box;
  height: auto;
  min-height: 100px;
  width: 260px;
  text-align: center;
`;

const ProgressContainer = styled.div`
  height: 16px;
  width: 90%;
  margin: 24px auto 0;
  display: block;
`;

export default withFadeOverlay(MessageBox);
