import { takeLatest } from 'redux-saga/effects';
import { submitUserExtraInfo, submitUserProfile } from '../../actions';
import { handleSubmitUserExtraInfo, handleSubmitUserProfile } from './handlers';

export function* submitUserProfileSaga() {
  yield takeLatest(submitUserProfile.type, handleSubmitUserProfile);
}

export function* submitUserExtraInfoSaga() {
  yield takeLatest(submitUserExtraInfo.type, handleSubmitUserExtraInfo);
}
