import { Router } from '@reach/router';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Api from './api';
import { ROUTER } from './config/router';
import './index.css';
import store from './redux/store';
import { isOpenedByApp } from './utils/check';
import App from './views/pages/App';
import PortraitModeOnly from './views/pages/App/PortraitModeOnly';
import SplashScreen from './views/pages/App/SplashScreen';
import HealthCondition from './views/pages/HealthCondition/index';
import Login from './views/pages/Login/index';
import SampleCollection from './views/pages/SampleCollection';
import Tester from './views/pages/Tester/index';
import TakePhoto from './views/pages/Tester/TakePhoto';
import TestResult from './views/pages/TestResult';
import HealthReport from './views/pages/TestResult/HealthReport';
import UnsupportedBrowser from './views/pages/UnsupportedBrowser';
import UserProfile from './views/pages/UserProfile';
import UserProfileExtra from './views/pages/UserProfile/UserProfileExtra';
import VaccinationInfo from './views/pages/VaccinationInfo/index';

const { render } = ReactDOM;

// mount splash
render(
  <Provider store={store}>
    <SplashScreen />
  </Provider>,
  document.getElementById('splash'),
);

// mount portrait mode overlay
render(<PortraitModeOnly />, document.getElementById('portrait-only'));

// mount unsupported app
const renderUnsupported = () => {
  render(
    <Provider store={store}>
      <UnsupportedBrowser />
    </Provider>,
    document.getElementById('root'),
  );
};

// mount main app
const renderApp = () => {
  render(
    <Provider store={store}>
      <Router>
        <App path={ROUTER.ROOT_DIR}>
          <Login path={ROUTER.LOGIN} default />
          <UserProfile path={ROUTER.USER_PROFILE} />
          <UserProfileExtra path={ROUTER.USER_PROFILE_EXTRA} />
          <SampleCollection path={ROUTER.SAMPLE_COLLECTION} />
          <HealthCondition path={ROUTER.HEALTH_CONDITION} />
          <VaccinationInfo path={ROUTER.VACCINATION_INFO} />
          <Tester path={`${ROUTER.TESTING_PROCESS}/*`} />
          <TakePhoto path={`${ROUTER.TAKE_PHOTO}`} />
          <TestResult path={`${ROUTER.TEST_RESULT}`} />
          <HealthReport path={`${ROUTER.HEALTH_REPORT}`} />
        </App>
      </Router>
    </Provider>,
    document.getElementById('root'),
  );

  // Prevent unload
  window.onbeforeunload = () => 'Are you sure you want to leave this page?';
};

// initializing
Api.setup(store.getState()).then(() => {
  if (isOpenedByApp()) {
    renderUnsupported();
  } else {
    renderApp();
  }
});
