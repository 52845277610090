import React from 'react';
import { connect } from 'react-redux';
import { goToNext, goToPrevious } from '../../../helpers/router';
import { getSampleData } from '../../../redux/selectors/index';
import { updateSampleData } from '../../../redux/slices/sampleSlice';
import SingleSelection from '../../components/forms/SingleSelection';
import BasePage from '../../components/layout/BasePage';
import SampleDatePicker from './SampleDatePicker';

const data = [
  {
    name: 'date',
    type: 'date',
    label: 'Sample Date',
  },
  {
    name: 'type',
    type: 'radio',
    title: 'Sample Type',
    options: [
      { label: 'Sputum', value: 'Sputum' },
      { label: 'Nasal Swab', value: 'Nasal swab' },
    ],
  },
  {
    name: 'collectedFrom',
    type: 'radio',
    title: 'Sample Collected From',
    options: [
      { label: 'Containment Zone', value: 'Containment Zone' },
      { label: 'Non-containment area', value: 'Non-containment area' },
    ],
  },
];

class SampleCollection extends BasePage {
  header = {
    title: 'Your Sample Collection Details',
    description: 'Please select your sample collection profiling details',
  };

  formId = 'sampleDataForm';

  onChange = this.props.updateSampleData;

  onClickPrev = (e) => {
    e.preventDefault();
    goToPrevious('SAMPLE_COLLECTION');
  };

  onClickNext = (e) => {
    e.preventDefault();
    if (this.allEntered(this.props.sample)) {
      goToNext('SAMPLE_COLLECTION');
    }
  };

  renderOptions() {
    const { sample } = this.props;

    return data.map((item, index) => {
      const { name, type } = item;
      const key = `sample-${index}`;
      const value = sample[name];
      if (type === 'date') {
        return <SampleDatePicker key={key} value={value} />;
      }
      return (
        <SingleSelection
          key={key}
          name={name}
          data={item}
          selectedValue={value}
        />
      );
    });
  }
}

const mapStateToProps = (state) => ({
  sample: getSampleData(state),
});

const mapDispatchToProps = {
  updateSampleData,
};

export default connect(mapStateToProps, mapDispatchToProps)(SampleCollection);
