import { isEmpty } from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { goToUserProfilePage } from '../../../helpers/router';
import { login } from '../../../redux/actions';
import Button from '../../components/forms/Button';
import FormGroup from '../../components/forms/FormGroup';
import { MobileInput } from '../../components/forms/inputs';
import AgePicker from '../../components/forms/selectors/AgePicker';
import withHeaderBox from '../../components/hoc/withHeaderBox';
import { HeaderBox } from '../../components/layout';
import { Title } from '../../components/text';
import { showErrorPopup } from '../../modal/popup';

class Login extends PureComponent {
  state = { birthDate: '', mobile: '', expand: false };

  onChange = ({ name, value }) => {
    this.setState({ [name]: value });
  };

  onLogin = () => {
    if (!this.state.expand) {
      this.setState({ expand: true });
      return;
    }
    const { birthDate, mobile } = this.state;
    if (isEmpty(birthDate)) {
      showErrorPopup('Please enter your date of birth.');
      return;
    }
    if (isEmpty(mobile)) {
      showErrorPopup('Please enter your phone number.');
      return;
    }
    this.props.login({ birthDate, mobile });
  };

  onCreateNewProfile = () => {
    goToUserProfilePage();
  };

  renderFirstTimeUser() {
    return (
      <HeaderBox theme="white">
        <Title>I am taking this test for the first time</Title>
        <Button onClick={this.onCreateNewProfile}>Create new profile</Button>
      </HeaderBox>
    );
  }

  renderReturnedUser() {
    const { birthDate, mobile, expand } = this.state;
    const buttonLabel = expand ? 'Continue' : 'Continue with my account';
    return (
      <HeaderBox theme="white">
        <Title>I have taken this test before</Title>
        {expand && (
          <>
            <FormGroup className="login-form" onChange={this.onChange} fadeIn>
              <Username
                value={birthDate}
                className="birth-picker"
                label="Date of Birth"
              />
              <Password
                value={mobile}
                className="mobile-field"
                label="Phone number"
                placeholder="Your phone number"
              />
            </FormGroup>
            <Button onClick={this.onLogin}>{buttonLabel}</Button>
          </>
        )}
        {!expand && <Button onClick={this.onLogin}>{buttonLabel}</Button>}
      </HeaderBox>
    );
  }

  render() {
    return (
      <div className="login">
        {this.renderFirstTimeUser()}
        {this.renderReturnedUser()}
      </div>
    );
  }
}

const mapDispatchToProps = {
  login,
};

export default connect(null, mapDispatchToProps)(Login);

const Username = withHeaderBox(AgePicker, 'login-field');

const Password = withHeaderBox(MobileInput, 'login-field');
