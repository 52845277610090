import { disableWindowScroll, enableWindowScroll } from '../../../helpers';
import { EVENT_HIDE_OVERLAY, EVENT_SHOW_OVERLAY } from './events';
import EventEmitter from './emmiter';

export function handleEmitEvent(action) {
  const { name, data = null } = action.payload;
  switch (name) {
    case EVENT_SHOW_OVERLAY: {
      disableWindowScroll();
      break;
    }
    case EVENT_HIDE_OVERLAY: {
      enableWindowScroll();
      break;
    }
    default: {
      EventEmitter.emit(name, data);
      break;
    }
  }
}
